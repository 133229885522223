import React from "react";
import styled from "@emotion/styled";

const Img = styled.img`
  width: ${(props) => (!!props.sm ? "50px" : "80px")};
  height: ${(props) => (!!props.sm ? "50px" : "50px")};
  object-fit: cover;
  border-radius: 5px;
`;

export default function ProductCardImage({ ...props }) {
  return <Img {...props} />;
}
