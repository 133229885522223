import React, { useEffect, useRef } from "react";
import CollectDetailsInput from "../../../../components/CollectDetailsInput";
import _ from "lodash";

export const FieldRenderer = ({
  item,
  index,
  currentFields,
  setMultipleFields,
  callback,
}) => {
  const valueDidChange = useRef(false);
  const updatingSubFields = useRef(false);
  const [selectedOption, setSelectedOption] = React.useState(
    item?.value ?? null
  );

  useEffect(() => {
    if (!valueDidChange.current) {
      setSelectedOption(item?.value ?? null);
    }
  }, [item]);

  useEffect(() => {
    if (updatingSubFields.current) {
      updatingSubFields.current = false;
    }
  }, [currentFields]);

  const getFieldOptions = (field) => {
    if (!field) {
      return [];
    }
    if (
      field.field_type !== "single_select" &&
      field.field_type !== "multi_select"
    ) {
      return field.field_options || [];
    }
    const options = field?.field_options || [];
    if (options.length > 0 && typeof options[0] === "string") {
      return options;
    } else if (options.length > 0 && typeof options[0] === "object") {
      return options.map((option) => ({
        value: option.field,
        label: option.field_label ?? option.field,
      }));
    }
    return [];
  };

  const options = getFieldOptions(item);

  const getSubFields = (field, value) => {
    if (!value) {
      return [];
    }
    const fieldOptions = getFieldOptions(field);
    const selectedOptionIndex = fieldOptions.findIndex(
      (option) => option.value === value
    );

    if (
      field.field_options &&
      field.field_options[selectedOptionIndex] &&
      typeof field.field_options[selectedOptionIndex] === "object"
    ) {
      return field.field_options[selectedOptionIndex].sub_fields || [];
    } else {
      return [];
    }
  };

  const getAllSubFields = (field, value, target) => {
    const itemSubFields = getSubFields(field, value);
    _.each(itemSubFields, (itemSubF) => {
      if (itemSubF.value) {
        getAllSubFields(itemSubF, itemSubF.value, target);
      }
    });
    target.push(...itemSubFields);
  };

  const subFields = React.useMemo(() => {
    if (!item) {
      return [];
    }
    const selectedOptionValue = selectedOption?.value || selectedOption;
    const fields = getSubFields(item, selectedOptionValue).map(
      (f) => currentFields[f.field] ?? f
    );

    // Add missing subfields to currentFields (orderData) on initial rendering
    if (
      !updatingSubFields.current &&
      fields.length &&
      fields.some((f) => !currentFields[f.field])
    ) {
      let newProgressData = _.cloneDeep(currentFields);
      const newSubFields = [];
      getAllSubFields(item, selectedOptionValue, newSubFields);

      _.each(newSubFields, (sf) => {
        if (
          !newProgressData[sf.field] ||
          !_.isEqual(newProgressData[sf.field], sf)
        ) {
          updatingSubFields.current = true;
          newProgressData[sf.field] = newProgressData[sf.field] ?? sf;
        }
      });

      setMultipleFields(newProgressData);
    }

    return fields;
  }, [item, selectedOption]);

  const handleSetDetails = (e) => {
    const prevOption = selectedOption?.value || selectedOption;
    valueDidChange.current = true;
    const selectedOptionValue = e?.value || e;
    const newSubFields = [];
    getAllSubFields(item, selectedOptionValue, newSubFields);
    const oldSubFields = [];
    getAllSubFields(item, prevOption, oldSubFields);

    if (!!currentFields && (oldSubFields.length || newSubFields.length)) {
      let newProgressData = _.cloneDeep(currentFields);

      // Remove last option subfields from currentFields (orderData)
      _.each(oldSubFields, (oldSubF) => {
        delete newProgressData[oldSubF.field];
      });

      // Add new subfields to currentFields (orderData)
      _.each(newSubFields, (sf) => {
        if (
          !newProgressData[sf.field] ||
          !_.isEqual(newProgressData[sf.field], sf)
        ) {
          updatingSubFields.current = true;
          newProgressData[sf.field] = newProgressData[sf.field] ?? sf;
        }
      });

      // Update current field
      newProgressData[e.field] = {
        ...newProgressData[e.field],
        value: e.value,
        error: !!e.error,
      };
      setMultipleFields(newProgressData);
      setSelectedOption(e);
    } else {
      setSelectedOption(e);
      callback(e);
    }
  };

  if (!item) {
    return null;
  }
  return (
    <>
      <CollectDetailsInput
        field={{
          field_type: item.field_type,
          name: item.field_label || item.field,
          field: item.field,
          required: item.required,
          field_options: options || [],
          value: item.value,
          error: item.error,
        }}
        index={index}
        setDetails={handleSetDetails}
      />

      {subFields && subFields.length > 0 && (
        <>
          {subFields.map((subItem, idx) => (
            <FieldRenderer
              key={idx}
              index={idx}
              item={subItem}
              currentFields={currentFields}
              setMultipleFields={setMultipleFields}
              callback={callback}
            />
          ))}
        </>
      )}
    </>
  );
};
