import React from "react";
import { makeStyles } from "@mui/styles";
import { alpha, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: "pointer",
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  label: {
    paddingTop: 1,
    color: alpha(theme.palette.text.primary, 0.5) + "!important",
  },
}));

const BackButton = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.button} onClick={onClick}>
      <IconButton
        onClick={onClick}
        disableRipple
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
      >
        <ArrowBackIcon color="primary.main" />
      </IconButton>
      <Typography textTransform={"uppercase"} className={classes.label}>
        {t("back")}
      </Typography>
    </div>
  );
};

export default BackButton;
