import React from "react";
import { Box, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProductCard from "ui/component/product/card/ProductCard";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2.5),
  },
}));

const UpsellsList = ({ upsells, limit = null, onItemClick, loading }) => {
  const classes = useStyles();

  if (!upsells.length) {
    return null;
  }
  return (
    <Box className={classes.list}>
      {loading
        ? [...Array(3).keys()].map((el) => (
            <Skeleton
              key={el}
              animation="wave"
              width={"calc(100% - 40px)"}
              height={142}
              sx={{
                borderRadius: "15px",
                transform: "none",
                mb: 1,
                ml: 2.5,
              }}
            />
          ))
        : upsells.map((pr, i) => {
            if (limit !== null && i >= limit) {
              return null;
            }
            return (
              <ProductCard
                key={pr.product_id}
                resource={pr}
                image={pr.header_image}
                title={pr.name}
                onClick={() => onItemClick(pr)}
              />
            );
          })}
    </Box>
  );
};

export default UpsellsList;
