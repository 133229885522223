import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import SkeletonList from "ui/base/list/SkeletonList";
import ProductSkuSelector from "./ProductSkuSelector";
import { FieldRenderer } from "./FieldRenderer";

const AvailabilityForm = ({
  loading,
  product,
  callback,
  productSkus,
  skuIndex,
  checkingAvailability,
  handleChangeSku,
}) => {
  const { t } = useTranslation();
  const skuNotAvailable = !productSkus[skuIndex]?.available;
  const defaultLang = i18next.language ?? "en";
  const productOptions = productSkus.map((ps, i) => ({
    value: `opt-${i}`,
    label: ps.name.find((n) => n.language === defaultLang)?.value,
    description: ps.description.find((d) => d.language === defaultLang)?.value,
    disabled: !ps.available,
  }));

  return (
    <>
      {loading ? (
        <SkeletonList
          size={3}
          width="100%"
          height={60}
          sx={{ borderRadius: "15px", mb: 2 }}
        />
      ) : (
        <>
          {/* TRAVEL DATE */}
          {!!product.availability_schedule && (
            <FieldRenderer
              item={product.availability_schedule}
              callback={callback}
            />
          )}
          {/* TRAVELERS */}
          {productSkus[skuIndex]?.enter_details?.map((item, idx) => (
            <FieldRenderer key={idx} item={item} callback={callback} />
          ))}
          {/* SKU SELECTOR */}
          <ProductSkuSelector
            fullWidth
            id="product-sku-selector"
            value={`opt-${skuIndex}`}
            error={skuNotAvailable}
            disabled={checkingAvailability}
            customErrorLabel={t("selected_option_not_available")}
            onChange={handleChangeSku}
            options={productOptions}
          />
        </>
      )}
    </>
  );
};

export default AvailabilityForm;
