import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const CustomButton = styled(Button)(({ theme }) => ({
  borderWidth: "2px !important",
  borderRadius: "30px",
  borderColor: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "18px",
  padding: theme.spacing(1.5, 2.5),
}));

const OutlinedButton = ({ label, ...props }) => {
  return (
    <CustomButton variant="outlined" disableRipple {...props}>
      {label}
    </CustomButton>
  );
};

export default OutlinedButton;
