import React from "react";
import {
  alpha,
  Checkbox,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import BaseInputLabel from "./InputLabel";
import BaseInput from "./BaseInput";
import WarningTag from "../tag/WarningTag";

const BaseSelector = ({
  id = "customized-select",
  label,
  value = "",
  fullWidth,
  options = [],
  onChange,
  multiple,
  defaultOption,
  error,
  required,
  customErrorLabel,
  topOptions = [],
  styleProps,
}) => {
  const { t } = useTranslation();
  const labelId = `${id}-label`;
  const inputId = `${id}-input`;
  const errorLabel = customErrorLabel ?? t("please_select");
  const empty = Array.isArray(value) ? !value.length : !value;

  const getMenuItem = (opt) => {
    return (
      <MenuItem key={opt.value} value={opt.value} disabled={opt.disabled}>
        {!!multiple && (
          <Checkbox
            checked={value.includes(opt.value)}
            sx={{ color: "primary.contrast" }}
          />
        )}
        {opt.label}
      </MenuItem>
    );
  };

  const divider = (
    <Divider
      component="li"
      variant="middle"
      sx={{ backgroundColor: "primary.main" }}
    />
  );

  return (
    <FormControl fullWidth={fullWidth} className={!!error ? "-error" : ""}>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={1}
        rowGap={0.5}
        columnGap={2}
        sx={{ flexFlow: "wrap" }}
      >
        <BaseInputLabel
          id={id}
          styleProps={styleProps}
          flex={1}
          sx={{ minWidth: "fit-content" }}
        >
          {`${label}${required ? " *" : ""}`}
        </BaseInputLabel>
        {!!error && <WarningTag label={errorLabel} />}
      </Stack>
      <Select
        id={id}
        multiple={multiple}
        labelId={labelId}
        value={value}
        displayEmpty
        label={label}
        onChange={onChange}
        renderValue={
          Array.isArray(value)
            ? (selected) => (!!empty ? [defaultOption] : selected.join(", "))
            : undefined
        }
        input={
          <BaseInput
            id={inputId}
            disableLabel
            placeholder={defaultOption}
            styleProps={styleProps}
          />
        }
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          sx: (theme) => ({
            "& .MuiMenuItem-root, & .MuiMenuItem-root *": {
              color: "primary.contrast",
              textWrap: "wrap",
            },
            "& .Mui-selected": {
              backgroundColor:
                alpha(theme.palette.primary.contrast, 0.08) + "!important",
            },
          }),
          PaperProps: {
            sx: {
              maxHeight: "80vh",
              backgroundColor: "primary.main",
            },
          },
        }}
        sx={(theme) => ({
          "& .MuiInputBase-input": {
            color: `${alpha(
              styleProps?.borderColor ?? theme.palette.primary.main,
              !!empty ? 0.5 : 1
            )} !important`,
          },
          "& .MuiSelect-icon": {
            color: styleProps?.borderColor ?? theme.palette.primary.main,
          },
        })}
      >
        <MenuItem disabled value={""} sx={{ opacity: 1 + "!important" }}>
          <Typography sx={{ opacity: 0.5 }}>
            {defaultOption ?? t("none")}
          </Typography>
        </MenuItem>
        {!!topOptions.length && topOptions.map((opt) => getMenuItem(opt))}
        {!!topOptions.length && divider}
        {options.map((opt) => getMenuItem(opt))}
      </Select>
    </FormControl>
  );
};

export default BaseSelector;
