import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import HTMLField from 'components/HTMLField'

const defaultBGImg = 'https://enso-image-assets.s3.amazonaws.com/article_placeholder_image.webp'

const useStyles = makeStyles(theme => ({
  card: {
    width: 'calc(50% - 6px)',
    borderRadius: '10px !important',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none !important',
  },
  cardContent: {
    padding: theme.spacing(1.5) + '!important',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  cardActionArea: {
    height: '100%',
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'flex-start !important',
  },
  cardMedia: {
    aspectRatio: 1,
    minHeight: 150,
    width: '100%',
    borderRadius: '10px !important',
  },
  name: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    marginBottom: '4px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    width: '100%',
    lineClamp: 2,
    WebkitLineClamp: 2,
  },
  description: {
    fontSize: '13px !important',
    fontWeight: '400 !important',
    lineHeight: '20px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    width: '100%',
    lineClamp: 2,
    WebkitLineClamp: 2,
    opacity: 0.7,
  },
}))


const DescriptionCard = ({ resource, onClick }) => {
  const classes = useStyles()

  return (<Card className={classes.card}>
    <CardActionArea disableRipple className={classes.cardActionArea} onClick={onClick}>
      <div
        className={classes.cardMedia}
        style={{ background: `url("${resource?.header_image ?? defaultBGImg}") no-repeat center center / cover` }}
      />
      <CardContent className={classes.cardContent}>
        <Box className={classes.textContainer}>
          <Typography className={classes.name}>
            {resource?.name || ''}
          </Typography>
          <HTMLField content={resource?.text ?? ''} className={classes.description} />
        </Box>
      </CardContent>
    </CardActionArea>
  </Card>)
}

export default DescriptionCard
