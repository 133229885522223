import React, { useEffect, useRef, useState } from "react";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

const VirtualizedList = ({
  items = [],
  itemSize,
  isItemLoaded,
  hasNextPage,
  loadMoreItems,
  getItem,
  loaderProps = {},
  listProps = {},
}) => {
  const containerRef = useRef(null);
  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const height = containerRef.current.getBoundingClientRect().height;
        setListHeight(height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const Item = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return <div style={style}>{getItem({ index, loading: true })}</div>;
    }
    return <div style={style}>{getItem({ index })}</div>;
  };

  return (
    <div ref={containerRef} style={{ height: "100%", flex: 1 }}>
      {listHeight > 0 && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={hasNextPage ? items.length + 1 : items.length}
          loadMoreItems={loadMoreItems}
          threshold={5}
          {...loaderProps}
        >
          {({ onItemsRendered, ref }) => (
            <List
              ref={ref}
              width={"100%"}
              height={listHeight}
              itemCount={items.length}
              itemSize={itemSize}
              onItemsRendered={onItemsRendered}
              {...listProps}
            >
              {Item}
            </List>
          )}
        </InfiniteLoader>
      )}
    </div>
  );
};

export default VirtualizedList;
