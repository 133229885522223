import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
// UI
import { makeStyles } from "@mui/styles";
import { SvgIcon, Typography } from "@mui/material";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as UpsellsIcon } from "assets/icons/shopping-bag.svg";
import { ReactComponent as GuidebooksIcon } from "assets/icons/book.svg";
import { hexToRgb } from "helpers";
import { AppContext } from "App";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  navbar: {
    gap: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "8px 30px",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000,
    background: theme.palette.primary.contrast + "a6",
    backdropFilter: "blur(8px)",
  },
  link: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    opacity: 0.6,
    padding: "8px 20px",
    flex: 1,
    maxWidth: 250,
    textDecoration: "none",
    outline: "none",
    WebkitTapHighlightColor: "transparent",
    "&.selected": {
      backgroundColor: hexToRgb(theme.palette.primary.main, 0.35),
      opacity: 1,
    },
  },
  icon: {
    color: theme.palette.primary.main,
    width: "24px !important",
    height: "24px !important",
  },
  text: {
    color: theme.palette.primary.main,
    width: "100%",
    textAlign: "center",
  },
}));

const Navbar = ({ hide, listingId }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const { kc, auth, loadingKeycard, loadingProducts } = useContext(AppContext);
  let hasUpgrades =
    !loadingKeycard && !loadingProducts
      ? !!kc?.components?.upsells?.length ||
        !!kc?.components?.purchased_upsells?.length ||
        !!kc?.components?.viator?.products?.length
      : true;
  let upgradesDisabled = loadingKeycard || loadingProducts;
  let hasGuides = !!kc?.components?.guidebooks?.length;
  let isHomeActive =
    location.pathname === `/${listingId}` ||
    location.pathname === `/${listingId}/` ||
    (location.pathname === "/" && location.search === `?auth=${auth}`);

  if (hide) {
    return null;
  }
  return (
    <div className={classes.navbar}>
      <NavLink
        to={!!listingId ? `/${listingId}/` : `/?auth=${auth}`}
        className={({ isActive }) =>
          [classes.link, isHomeActive ? "selected" : ""].join(" ")
        }
      >
        <SvgIcon
          viewBox="0 0 24 24"
          className={classes.icon}
          component={HomeIcon}
        />
        <Typography className={classes.text}>{t("home_page")}</Typography>
      </NavLink>
      {!!hasUpgrades && (
        <NavLink
          to={
            upgradesDisabled
              ? "#"
              : !!listingId
              ? `/${listingId}/upgrades`
              : `/upgrades/?auth=${auth}`
          }
          className={({ isActive }) =>
            clsx(classes.link, {
              selected:
                (isActive && !upgradesDisabled) ||
                location.pathname?.includes("/upgrades"),
            })
          }
          onClick={(e) => {
            if (upgradesDisabled) {
              e.preventDefault();
            }
          }}
        >
          <SvgIcon
            viewBox="0 0 24 24"
            className={classes.icon}
            component={UpsellsIcon}
          />
          <Typography className={classes.text}>{t("upgrades")}</Typography>
        </NavLink>
      )}
      {!!hasGuides && (
        <NavLink
          to={!!listingId ? `/${listingId}/guides` : `/guides/?auth=${auth}`}
          className={({ isActive }) =>
            [classes.link, isActive ? "selected" : ""].join(" ")
          }
        >
          <SvgIcon
            viewBox="0 0 24 24"
            className={classes.icon}
            component={GuidebooksIcon}
          />
          <Typography className={classes.text}>{t("guides")}</Typography>
        </NavLink>
      )}
    </div>
  );
};

export default Navbar;
