import React from "react";
import { Skeleton } from "@mui/material";

const SkeletonList = ({
  size = 4,
  width = "100%",
  height = 100,
  sx = {},
  ...skeletonProps
}) => {
  return (
    <div>
      {[
        ...Array(size)
          .keys()
          .map((el) => (
            <Skeleton
              key={el}
              animation="wave"
              width={width}
              height={height}
              sx={{
                transform: "none",
                ...sx,
              }}
              {...skeletonProps}
            />
          )),
      ]}
    </div>
  );
};

export default SkeletonList;
