import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { InputAdornment } from "@mui/material";
import { useImageCompressor } from "useImageCompressor";
import { useTranslation } from "react-i18next";
import BaseInput from "ui/base/input/BaseInput";

export default function UploadImageField({ field, index, onChange }) {
  const hasErrors = !!field.error;
  const { t } = useTranslation();
  const imgInputRef = React.useRef();
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload.file] : [],
    onSuccess: (outputImg) => onCompressSuccess(outputImg),
  });

  const browseFile = () => {
    if (imgInputRef.current) imgInputRef.current.click();
  };

  const onCompressSuccess = (outputImg) => {
    setCompressEnabled((prev) => false);
    handleSelectImage(outputImg[0], true);
  };

  const handleSelectImage = (file, compressed) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      let result = event.target.result;
      let newFile = { index, file, result };
      if (!!compressed) {
        onChange(newFile);
      } else {
        setImgToUpload(newFile);
        setCompressEnabled((prev) => true);
      }
    };
  };

  return (
    <>
      <input
        ref={imgInputRef}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        onChange={(e) => handleSelectImage(e.target.files[0])}
      />
      <BaseInput
        id="image-uploader"
        fullWidth
        label={field.name}
        placeholder={t("browse")}
        required={field.required}
        value={imgToUpload?.file?.name ?? ""}
        error={hasErrors}
        onClick={browseFile}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{ position: "absolute", right: 12 }}
          >
            <UploadFileIcon sx={{ color: "primary.main" }} />
          </InputAdornment>
        }
      />
    </>
  );
}
