import React from "react";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BaseInputLabel from "ui/base/input/InputLabel";
import WarningTag from "ui/base/tag/WarningTag";
import "react-phone-input-2/lib/material.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    backgroundColor: "transparent!important",
    border: "none !important",
    color: theme.palette.primary.main,
    borderRadius: "10px !important",
    padding: "18.5px 0 18.5px 60px !important",
    width: "100%!important",
    "&:focus": { boxShadow: "none!important" },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground + "!important",
    },
  },
  phoneInputDropdown: { border: "none!important" },
  phoneInputContainer: {
    border: `solid 1.5px ${theme.palette.primary.main}`,
    width: "99.5%",
    borderRadius: 10,
    "& > .flag-dropdown": {
      borderRight: `1.5px solid ${theme.palette.primary.main} !important`,
      backgroundColor: theme.palette.primary.contrast,
      borderRadius: "15px 0 0 15px",
    },
  },
}));

export default function PhoneComponent({
  label,
  country,
  error,
  required,
  initialCode,
  phone,
  onChange,
  ...other
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const onPhoneChange = (value, country, e, formattedValue) => {
    onChange(value);
  };

  return (
    <Stack flexDirection={"column"} className={!!error ? "-error" : ""}>
      {!!label && (
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={1}
          rowGap={0.5}
          columnGap={2}
        >
          <BaseInputLabel
            htmlFor={"phone-input"}
            flex={1}
            sx={{ minWidth: "fit-content" }}
          >
            {`${label}${required ? " *" : ""}`}
          </BaseInputLabel>
          {!!error && <WarningTag label={t("please_fill_in")} />}
        </Stack>
      )}
      <PhoneInput
        enableSearch
        country={country}
        specialLabel=""
        disableSearchIcon
        countryCodeEditable={false}
        placeholder=""
        priority={{ ca: 0, us: 1 }}
        preferredCountries={["ca", "us", "fr"]}
        dropdownClass={classes.phoneInputDropdown}
        containerClass={clsx(classes.phoneInputContainer, {
          "-error": !!error,
        })}
        inputClass={classes.phoneInput}
        onChange={onPhoneChange}
        value={phone}
        {...other}
      />
    </Stack>
  );
}
