import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const Card = styled(Stack)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "20px",
  padding: "10px",
  alignItems: "center",
  flexDirection: "row",
  gap: theme.spacing(2),
}));

const InfoCTACard = ({ icon, message, action }) => {
  return (
    <Card>
      {icon}
      <Typography>{message}</Typography>
      {action}
    </Card>
  );
};

export default InfoCTACard;
