import { Typography } from "@mui/material";

export default function HTMLContent({ v, styleOptions = {} }) {
  return (
    <Typography
      className="html-content"
      style={{
        wordWrap: "break-word",
        whiteSpace: "break-spaces",
        ...styleOptions,
      }}
      dangerouslySetInnerHTML={{ __html: v }}
    />
  );
}
