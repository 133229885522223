import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  List,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner";
import { getPurchase, savePurchase } from "apiActions";
import { useTranslation } from "react-i18next";
import { AppContext } from "App";
import BaseProductDialog from "./BaseProductDialog";
import HTMLContent from "../../../../components/HTMLContent";
import SelectedOptionItem from "ui/base/list/SelectedOptionItem";

export default function StandardProductDialog({
  product,
  onClose,
  productType,
  onPaymentRedirect = () => {},
  setStripeAccount = () => {},
}) {
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const { kc, auth, setAlertOptions } = useContext(AppContext);
  const [url, setUrl] = useState(null);
  const [purchaseGetError, setPurchaseGetError] = useState(false);
  const isFreeUpsell =
    productType === "up" && !product?.skus.find((s) => s.value > 0);
  const multipleSkus = product?.skus?.length > 1;
  const [selectedSku, setSelectedSku] = useState(multipleSkus ? null : 0);
  const [isPurchasing, setIsPurchasing] = useState(false);

  const productTotal =
    selectedSku === null
      ? ""
      : !product?.skus?.[selectedSku].value
      ? ""
      : `${product?.skus?.[selectedSku].formatted_value} - `;
  const purchaseWord =
    selectedSku === null
      ? t("make_your_selection")
      : product?.skus[selectedSku].charge_card === false
      ? t("place_hold")
      : !product?.skus[selectedSku].value
      ? t("request")
      : t("purchase");

  useEffect(() => {
    if (!isFreeUpsell && url === null) {
      getPurchase({
        auth,
        booking_id: kc?.booking_id,
        product_id: product.product_id,
        resource: productType,
        onSuccess: (response) => {
          setUrl(response.data.url);
          setStripeAccount(response.data.url?.account_id ?? null);
        },
        onError: () => {
          setPurchaseGetError(true);
        },
      });
    }
  }, []);

  const scrollToBottom = () => {
    contentRef.current?.scrollTo({
      top: contentRef.current?.scrollHeight || 0,
      behavior: "smooth",
    });
  };

  const renderProductContent = ({ classes }) => {
    const getSku = (ind) => {
      const curr_sku = product.skus[ind];
      return (
        <ListItem
          key={`sku-${ind}`}
          disablePadding
          className={classes.sku_item}
        >
          <ListItemButton
            role={undefined}
            onClick={() => setSelectedSku(ind)}
            dense
          >
            <ListItemIcon className={classes.itemIcon}>
              <Radio
                checked={ind === selectedSku}
                tabIndex={-1}
                className={classes.radioBtn}
                value={`skuselect-${ind}`}
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <div className={classes.itemContainer}>
                  <Typography className={classes.itemDescription}>
                    {curr_sku.name}
                  </Typography>
                  <Typography className={classes.price}>
                    {curr_sku.formatted_value}
                  </Typography>
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
      );
    };

    return (
      <div className={classes.content}>
        <HTMLContent v={product.description} />

        {multipleSkus && (
          <List className={classes.sku_box}>
            {product.skus.map((sku, i) => getSku(i))}
          </List>
        )}
      </div>
    );
  };

  const handlePurchase = () => {
    if (selectedSku === null) {
      scrollToBottom();
      return;
    }

    const sku = product.skus[selectedSku];
    const sku_id = sku.sku_id;

    if (sku.value === 0) {
      setIsPurchasing(true);
      const body = {
        auth,
        sku_id,
        resource: productType,
        booking_id: kc?.booking_id,
      };
      savePurchase({
        body,
        setAlertOptions,
        onSuccess: () => setIsPurchasing(false),
        onError: () => setIsPurchasing(false),
      });
    } else {
      const action = url[sku_id];

      if (action.external_redirect) {
        window.open(action.url);
      } else if (action.payment_redirect) {
        onPaymentRedirect(action.url);
      } else {
        const body = {
          auth,
          session_id: action.session_id,
          sku_id,
          resource: productType,
          booking_id: kc?.booking_id,
        };
        setIsPurchasing(true);
        savePurchase({
          body,
          setAlertOptions,
          onSuccess: () => setIsPurchasing(false),
          onError: () => setIsPurchasing(false),
        });
      }
    }
  };

  const notLoaded = url === null && !isFreeUpsell;

  const getPurchaseButtonContent = () => {
    if (notLoaded) {
      if (purchaseGetError) {
        return "Failed to Load - Please Contact your Host";
      }
      return <LoadingSpinner />;
    }
    return (
      <span>
        {selectedSku !== null && productTotal}
        {purchaseWord}
      </span>
    );
  };

  const actionPanel = ({ classes }) => (
    <div className={classes.actionsContainer}>
      <SelectedOptionItem
        name={product.skus[selectedSku]?.name}
        price={product.skus[selectedSku]?.formatted_value}
        selectedOption={selectedSku}
      />
      <Button
        disableRipple
        disabled={notLoaded || isPurchasing}
        className={classes.btn}
        onClick={handlePurchase}
        fullWidth
        size="large"
        variant="contained"
      >
        {getPurchaseButtonContent()}
      </Button>
    </div>
  );

  return (
    <BaseProductDialog
      product={product}
      onClose={onClose}
      actionPanel={actionPanel}
      renderProductContent={renderProductContent}
      contentRef={contentRef}
    />
  );
}
