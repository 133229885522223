import React from "react";
import { Box, Rating, Stack, Typography } from "@mui/material";
import { convertMinutes, getCurrency } from "helpers";
import ClockIcon from "@mui/icons-material/Schedule";
import PriceTag from "../../../base/tag/PriceTag";
import styled from "@emotion/styled";
const defaultBGImg =
  "https://enso-image-assets.s3.amazonaws.com/article_placeholder_image.webp";

const Duration = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: "13px !important",
  lineHeight: "12px !important",
  opacity: 0.75,
}));

const Reviews = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "12px",
  opacity: 0.75,
  paddingTop: 1,
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  wordBreak: "break-word",
  width: "100%",
  fontSize: "15px",
  fontWeight: "500",
  lineHeight: "20px",
  lineClamp: 3,
  WebkitLineClamp: 3,
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  width: "38%",
  maxHeight: "100%",
  borderRadius: 10,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
}));

const Card = styled(Box)(({ theme, noImage, noRating }) => ({
  cursor: "pointer",
  borderRadius: 15,
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.contrastLight,
  height: noRating ? 82 : 100,
  margin: theme.spacing(0, 2.5),
  WebkitTapHighlightColor: "transparent",
  paddingLeft: noImage ? theme.spacing(1) : theme.spacing(0.5),
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconFilled": {
    color: theme.palette.primary.main,
  },
  "& .MuiSvgIcon-root": {
    width: 13,
    height: 13,
  },
}));

const ProductCard = ({
  title = "",
  image,
  rating = null,
  reviews = null,
  price = null,
  currency = "USD",
  duration = null,
  onClick,
}) => {
  let durationFormatted =
    duration !== null
      ? Array.isArray(duration)
        ? [convertMinutes(duration[0]), convertMinutes(duration[1])]
        : convertMinutes(duration)
      : null;

  return (
    <Card
      noImage={!image}
      noRating={rating === null && duration === null}
      onClick={onClick}
    >
      <ImgContainer
        sx={{
          background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%), url("${
            image ?? defaultBGImg
          }") no-repeat center center / cover`,
        }}
      >
        {price !== null && (
          <Box sx={{ position: "absolute", bottom: 4, left: 4 }}>
            <PriceTag
              bold
              size="sm"
              fontSize={16}
              price={getCurrency({ price, currency })}
              variant="dark"
            />
          </Box>
        )}
      </ImgContainer>
      <Stack
        flex={1}
        p={1}
        flexDirection={"column"}
        gap={1}
        justifyContent={"space-between"}
      >
        <CardTitle>{title}</CardTitle>
        {(rating !== null || duration !== null) && (
          <Stack flexDirection={"row"} alignItems={"center"} gap={0.5}>
            {rating !== null && (
              <StyledRating
                readOnly
                value={rating}
                precision={0.5}
                size="small"
              />
            )}
            {reviews !== null && <Reviews>{reviews}*</Reviews>}
            {duration !== null && (
              <Stack
                flex={1}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                maxHeight={14}
                gap={0.5}
              >
                <ClockIcon color="primary" sx={{ fontSize: 14 }} />
                <Duration>
                  {Array.isArray(durationFormatted)
                    ? `${durationFormatted[0]} - ${durationFormatted[1]}`
                    : durationFormatted}
                </Duration>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default ProductCard;
