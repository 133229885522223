import React, { useContext } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "App";
import PurchasedProductCard from "../card/PurchasedProductCard";
import { getCurrency, getStatusLabel } from "helpers";
import PrimaryButton from "ui/base/button/PrimaryButton";

const limit = 3;

const OrdersList = ({ purchases, loading }) => {
  const { listing_id } = useParams;
  const navigate = useNavigate();
  const { auth } = useContext(AppContext);
  const { t } = useTranslation();

  const goToPurchases = (item = null) => {
    let path = !!listing_id
      ? `/${listing_id}/purchases`
      : `/purchases/?auth=${auth}`;
    navigate(path, { state: { item } });
  };

  if (!purchases.length) {
    return null;
  }
  return (
    <Stack flexDirection={"column"} gap={1}>
      {loading ? (
        [...Array(3).keys()].map((el) => (
          <Skeleton
            key={el}
            animation="wave"
            width={"calc(100% - 40px)"}
            height={142}
            sx={{
              borderRadius: "15px",
              transform: "none",
              mb: 1,
              ml: 2.5,
            }}
          />
        ))
      ) : (
        <>
          <Box>
            <Typography variant="body2">
              {t("upcoming_upgrades").toUpperCase()}
            </Typography>
          </Box>
          {purchases.map((p_u, i) => {
            if (limit !== null && i >= limit) {
              return null;
            }
            return (
              <PurchasedProductCard
                key={p_u.bill.id}
                title={p_u.bill.title}
                image={
                  p_u.bill.header_image ?? p_u.bill.custom?.header_image?.value
                }
                price={getCurrency({
                  price: p_u.bill.total,
                  currency: p_u.bill.currency,
                })}
                status={getStatusLabel(p_u.bill.status, t)}
                onClick={() => goToPurchases(p_u.bill.id)}
              />
            );
          })}
          <Box mt={1.5}>
            <PrimaryButton
              variant="transparent"
              onClick={() => goToPurchases()}
              label={t("see_order_history")}
            />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default OrdersList;
