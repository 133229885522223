import React from "react";
// UI
import { makeStyles } from "@mui/styles";
import { Skeleton } from "@mui/material";
// Custom
import VerticalCard from "components/Cards/VerticalCard";
import Carousel from "ui/base/carousel/Carousel";

const useStyles = makeStyles((theme) => ({
  carouselWrapper: { position: "relative" },
}));

const ProductCarousel = ({ upsells, onItemClick, loading }) => {
  const classes = useStyles();

  return (
    <Carousel wrapperClass={classes.carouselWrapper}>
      {loading
        ? [...Array(10).keys()].map((el) => (
            <Skeleton
              key={el}
              animation="wave"
              width={250}
              height={400}
              sx={{
                transform: "none",
                ml: el === 0 ? 2.5 : 1.5,
              }}
            />
          ))
        : upsells.map((up, i) => (
            <VerticalCard
              key={up.product_id}
              itemId={up.product_id}
              resource={up}
              firstItem={i === 0}
              lastItem={i === upsells.length - 1 || i === upsells.length - 1}
              onClick={() => onItemClick(up)}
            />
          ))}
    </Carousel>
  );
};

export default ProductCarousel;
