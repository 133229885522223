import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Popover,
  IconButton,
  Typography,
  Grid,
  Box,
  useTheme,
  Stack,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import MenuButton from "../../../base/button/MenuButton";
import { useTranslation } from "react-i18next";
import PrimaryButton from "ui/base/button/PrimaryButton";
import WarningTag from "ui/base/tag/WarningTag";
import { ViatorProductContext } from "../dialog/ViatorProductDialog";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "transparent !important",
  },
}));

const TravelerSelector = ({ field, onClose }) => {
  const classes = useStyles();
  const travelerOptions =
    field?.field_options?.map((option) => ({
      label: option.field_label,
      value: option.validation?.min_value ?? 0,
      field: option.field,
      max_value: option.validation?.max_value,
      min_value: option.validation?.min_value,
    })) || [];

  const maxTravelers = travelerOptions[0].max_value;

  const { t } = useTranslation();
  const theme = useTheme();
  const { adultRequired } = useContext(ViatorProductContext);
  const [travelerCounts, setTravelerCounts] = useState(travelerOptions);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const fontColor = theme.palette.getContrastText(theme.palette.primary.main);

  const hasAdults = useMemo(() => {
    return travelerCounts.some(
      (adult) =>
        ["PAX_MIX_ADULT", "PAX_MIX_SENIOR", "PAX_MIX_TRAVELER"].includes(
          adult.field
        ) && !!adult.value
    );
  }, [travelerCounts]);

  const travelers = useMemo(() => {
    return travelerCounts.reduce((a, b) => a + b.value, 0);
  }, [travelerCounts]);

  const showAdultRequiredWarning = adultRequired && !hasAdults;

  useEffect(() => {
    if (!!travelers) {
      onClose(travelerCounts);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose(travelerCounts, showAdultRequiredWarning);
  };

  const handleIncrement = (label) => {
    setTravelerCounts((prevCounts) => {
      const totalTravelers = prevCounts.reduce((a, b) => a + b.value, 0);
      return prevCounts.map((option) =>
        option.label === label &&
        totalTravelers < maxTravelers &&
        option.value < option.max_value
          ? { ...option, value: option.value + 1 }
          : option
      );
    });
  };

  const handleDecrement = (label) => {
    setTravelerCounts((prevCounts) =>
      prevCounts.map((option) =>
        option.label === label && option.value > option.min_value
          ? { ...option, value: option.value - 1 }
          : option
      )
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      <MenuButton
        fullWidth
        required
        error={field.error && (!travelers || showAdultRequiredWarning)}
        customErrorLabel={!travelers ? t("please_select") : t("adult_required")}
        label={t("select_travelers")}
        id={"country-select"}
        onClick={handleClick}
        value={`${travelers} Travelers`}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.paper }}
      >
        <Stack
          flexDirection={"column"}
          gap={2.5}
          sx={{
            padding: 2.5,
            backgroundColor: "primary.main",
            borderRadius: "10px",
          }}
        >
          <Typography variant="body2" sx={{ opacity: 1, color: fontColor }}>
            {t("select_up_to").replace("{number}", maxTravelers)}
          </Typography>
          {travelerCounts.map((option) => (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              key={option.label}
            >
              <Stack>
                <Typography sx={{ color: fontColor }}>
                  {option.label}
                </Typography>
                <Typography
                  sx={{ color: fontColor, fontSize: 12, lineHeight: "12px" }}
                >
                  {`${t("minimum")}: ${option.min_value}, ${t("maximum")}: ${
                    option.max_value
                  }`}
                </Typography>
              </Stack>
              <div>
                <IconButton
                  onClick={() => handleDecrement(option.label)}
                  disabled={option.value <= option.min_value}
                  sx={(theme) => ({
                    color: theme.palette.primary.contrast + "!important",
                    border: `2px solid ${theme.palette.primary.contrast}`,
                    padding: 0,
                    "&.Mui-disabled": { opacity: 0.5 },
                  })}
                >
                  <Remove sx={{ fontSize: 16 }} />
                </IconButton>
                <Typography
                  display="inline"
                  sx={(theme) => ({
                    margin: theme.spacing(0, 2.5),
                    fontWeight: 700,
                    color: fontColor,
                  })}
                >
                  {option.value}
                </Typography>
                <IconButton
                  onClick={() => handleIncrement(option.label)}
                  disabled={
                    travelers >= maxTravelers ||
                    option.value >= option.max_value
                  }
                  sx={(theme) => ({
                    color: theme.palette.primary.contrast + "!important",
                    border: `2px solid ${theme.palette.primary.contrast}`,
                    padding: 0,
                    "&.Mui-disabled": { opacity: 0.5 },
                  })}
                >
                  <Add sx={{ fontSize: 16 }} />
                </IconButton>
              </div>
            </Grid>
          ))}
          {(showAdultRequiredWarning || travelers >= maxTravelers) && (
            <WarningTag
              variant="mainLight"
              label={t(
                showAdultRequiredWarning
                  ? "adult_required"
                  : "max_travelers_reached"
              )}
            />
          )}
          <PrimaryButton
            variant="contrast"
            onClick={handleClose}
            label={t("apply")}
          />
        </Stack>
      </Popover>
    </Box>
  );
};

export default TravelerSelector;
