import React from "react";
import { Stack, Typography } from "@mui/material";
import { gradientRgb } from "helpers";
import styled from "@emotion/styled";

const Container = styled(Stack)(({ theme }) => ({
  width: "100vw",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  minHeight: "25vh",
}));

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: 800,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  gap: 12,
  marginTop: -68,
}));

const SectionHeaderCard = ({
  img,
  title,
  actions,
  titleRef,
  disableGrayscale,
}) => {
  return (
    <>
      <Container
        sx={(theme) => ({
          background: `linear-gradient(180deg, rgba(255,255,255,0) 30%, ${gradientRgb(
            theme.palette.primary.contrast
          )} 95%), url("${img}") no-repeat center center / cover`,
        })}
      ></Container>
      <Content ref={titleRef}>
        <Typography
          variant="h3"
          component={"div"}
          lineHeight={"40px"}
          sx={(theme) => ({ padding: theme.spacing(0, 2.5) })}
        >
          {title}
        </Typography>
        {!!actions && actions}
      </Content>
    </>
  );
};

export default SectionHeaderCard;
