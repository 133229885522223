import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isIOS, isMacOs } from "react-device-detect";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
// UI
import { Box, ButtonBase, IconButton, Stack } from "@mui/material";
import { AppleWalletIcon } from "assets/Icons.jsx";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import getTheme from "theme";
// Custom
import ProductContactDetailsDialog from "ui/component/product/dialog/ProductContactDetailsDialog";
import OrderDetailsCard from "ui/component/product/card/OrderDetailsCard";
import SectionHeaderCard from "components/Cards/SectionHeaderCard";
import BrandingFooter from "components/BrandingFooter";
import SkeletonList from "ui/base/list/SkeletonList";
import LoadingScreen from "components/Loading";
// API
import { loadData, loadProductData } from "api";
// Utils
import { AppContext, allowedViewsToShowInfo } from "App";
import defaultBGImg from "assets/checkout-img.jpg";
import { getAppleWallet } from "helpers";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    width: "-webkit-fill-available",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "auto",
  },
  content: {
    flex: 1,
    width: "calc(100% - 40px)",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    maxWidth: 800,
    marginTop: "40px",
  },
  absoluteRow: {
    position: "absolute",
    top: 0,
    left: "auto",
    right: "auto",
    zIndex: 1,
    width: "100%",
    maxWidth: 800,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(2.5),
  },
  buttonRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(0.5),
  },
  closeBtn: {
    backgroundColor: `${theme.palette.primary.contrast + "a6"} !important`,
    padding: theme.spacing(1.5) + "!important",
  },
  icon: {
    fontSize: "24px !important",
    color: theme.palette.primary.main,
  },
}));

const OrderHistoryPage = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const billsRef = useRef({});
  const {
    kc,
    view,
    setView,
    auth,
    setKc,
    setTheme,
    loadingKeycard,
    loadingProducts,
    setLoadingKeycard,
    setLoadingProducts,
  } = useContext(AppContext);
  const { listing_id } = useParams();
  const [expanded, setExpanded] = useState([]);
  const [selectedBookingRef, setSelectedBookingRef] = useState(false);
  let isListingBP = !location.search;
  let components = kc?.components ?? {};
  let bookingId = kc?.booking_id;
  const hasUpsells =
    !!components.upsells?.length ||
    !!components.purchased_upsells?.length ||
    !!components.viator?.products?.length;
  let pendingActions = [
    ...(components?.pending_upsells || []),
    ...(components?.requires_action_upsell || []),
  ];
  let purchasedUpsells = components?.purchased_upsells || [];

  function redirectToHome() {
    window.location.replace(
      location.pathname.replace("/purchases", isListingBP ? "/" : "") +
        location.search
    );
  }

  const redirectToUpsells = () => {
    let path = !!listing_id
      ? `/${listing_id}/upgrades`
      : `/upgrades/?auth=${auth}`;
    navigate(path, { replace: true });
  };

  const getProducts = () => {
    setLoadingKeycard((prev) => true);
    const keycardPromise = loadData(auth, listing_id, null);
    const productPromise = loadProductData(auth, listing_id, null, true);

    keycardPromise
      .then((r) => {
        setKc(r.data);
        const dataComponents = r.data.components;
        const guest_language = dataComponents?.guest?.language;
        if (!!guest_language) {
          i18next.changeLanguage(guest_language);
        }
        let metadata = dataComponents.metadata;
        document.body.style.background = metadata.color2;
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute("content", metadata.color2);
        window.title = `Boarding Pass - ${dataComponents.metadata.name}`;
        setTheme(getTheme(metadata));
        setView(r.data.view);
        setLoadingKeycard((prev) => false);
        if (!allowedViewsToShowInfo.includes(r.data.view)) {
          redirectToHome();
        }
      })
      .catch((e) => {
        setView("error");
        setLoadingKeycard((prev) => false);
      });

    setLoadingProducts(true);
    productPromise
      .then(async (r) => {
        const keycardData = await keycardPromise;
        const updated_components = {
          ...(keycardData.data.components || {}),
          ...r.data,
        };
        setKc({ ...keycardData.data, components: updated_components });
      })
      .catch((e) => {
        setView("error");
        setLoadingKeycard((prev) => false);
      })
      .finally(() => setLoadingProducts(false));
  };

  useEffect(() => {
    if (!kc) {
      getProducts();
    } else if (!hasUpsells) {
      redirectToHome();
    }

    if (location?.state?.item) {
      setExpanded((prev) => [...prev, location.state.item]);
      billsRef.current[location.state.item]?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      navigate(`${location.pathname}${location.search}`, {
        state: {
          ...location.state,
          item: null,
        },
        replace: true,
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  const toggleExpanded = (itemId) => () => {
    if (expanded.includes(itemId)) {
      setExpanded((prev) => prev.filter((e) => e !== itemId));
    } else {
      setExpanded((prev) => [...prev, itemId]);
    }
  };

  if (loadingKeycard) {
    return <LoadingScreen />;
  }
  return (
    <div className={classes.container}>
      <ProductContactDetailsDialog
        bookingRef={selectedBookingRef}
        setOpen={setSelectedBookingRef}
      />
      <SectionHeaderCard title={t("purchases")} img={defaultBGImg} />
      <div className={classes.absoluteRow}>
        <Box className={classes.buttonRow}>
          <IconButton onClick={redirectToUpsells} className={classes.closeBtn}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Box>
      </div>
      <div className={classes.content}>
        {/* Upsells */}
        <Stack mt={!!pendingActions.length ? 0 : 5} gap={2}>
          {loadingProducts ? (
            <SkeletonList height={400} sx={{ borderRadius: "10px", mb: 2 }} />
          ) : (
            purchasedUpsells.map((p) => (
              <OrderDetailsCard
                key={p.bill.id}
                orderRef={(el) => {
                  if (el) {
                    billsRef.current[p.bill.id] = el;
                  } else {
                    delete billsRef.current[p.bill.id];
                  }
                }}
                bill={p.bill}
                language={i18next.language ?? "en"}
                headerImage={p.header_image}
                expanded={expanded.includes(p.bill.id)}
                onExpand={toggleExpanded(p.bill.id)}
                onContact={() => setSelectedBookingRef(p.bill.session_id)}
              />
            ))
          )}
        </Stack>
      </div>
      {/* Branding footer */}
      <Box mt={7.5} display="flex" flexDirection="column" alignItems="center">
        <BrandingFooter
          metadata={components?.metadata}
          extras={
            view !== "revoked" && (isMacOs || isIOS) ? (
              <ButtonBase
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() =>
                  getAppleWallet(auth, bookingId, setLoadingKeycard)
                }
              >
                <AppleWalletIcon />
              </ButtonBase>
            ) : null
          }
        />
      </Box>
    </div>
  );
};

export default OrderHistoryPage;
