import React from "react";
import { CircularProgress } from "@mui/material";
import styled from "@emotion/styled";

const CustomizedCircularProgress = styled(CircularProgress)(
  ({ theme, transparent }) => ({
    color: transparent ? "primary.main" : theme.palette.primary.contrast,
  })
);

const LoadingInputLabel = ({ transparent }) => {
  return <CustomizedCircularProgress size={18} transparent={transparent} />;
};

export default LoadingInputLabel;
