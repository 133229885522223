import React from "react";
import { Stack, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ProductCardImage from "ui/base/img/ProductCardImage";
import RowLabel from "ui/base/label/RowLabel";
import CustomRow from "ui/base/grid/CustomRow";

const SummaryCard = ({ product, image, persons = null, date, total }) => {
  const { t } = useTranslation();

  const getRow = (label, value, valueProps) => {
    return (
      <CustomRow gap={2}>
        <RowLabel>{label}</RowLabel>
        <Typography
          align="right"
          sx={{ overflowWrap: "anywhere" }}
          {...valueProps}
        >
          {value}
        </Typography>
      </CustomRow>
    );
  };

  return (
    <Stack
      bgcolor={"primary.contrastLight"}
      borderRadius={"10px"}
      p={2.5}
      spacing={1.5}
    >
      <CustomRow spacing={1}>
        <Typography
          fontSize={isMobile ? 20 : 22}
          lineHeight={"30px"}
          sx={{ flex: 1 }}
        >
          {product}
        </Typography>
        {!!image && <ProductCardImage src={image} alt={product} />}
      </CustomRow>
      {getRow(t("travelers"), persons)}
      {getRow(t("date"), date)}
      {getRow(t("total_price"), total, { fontWeight: 700 })}
    </Stack>
  );
};

export default SummaryCard;
