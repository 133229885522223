import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const Tag = styled(Box)(({ theme, variant, size }) => ({
  backgroundColor:
    variant === "contrast"
      ? theme.palette.primary.main
      : variant === "dark"
      ? "#000000"
      : theme.palette.primary.contrast,
  borderRadius: "5px",
  padding: size === "sm" ? theme.spacing(0.5, 1) : theme.spacing(1, 1.5),
  width: "fit-content",
  height: "fit-content",
}));

const PriceTag = ({
  size = "md",
  fontSize = 14,
  variant = "main",
  price = "",
  bold = false,
}) => {
  return (
    <Tag variant={variant} size={size}>
      <Typography
        fontSize={fontSize}
        fontWeight={!!bold ? 700 : 400}
        color={
          variant === "contrast"
            ? "primary.contrast"
            : variant === "dark"
            ? "#FFFFFF"
            : "primary.main"
        }
      >
        {price}
      </Typography>
    </Tag>
  );
};

export default PriceTag;
