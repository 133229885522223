import React, { useContext, useEffect, useRef, useState } from "react";
// Utils
import { formatProductFields, getCurrency, newUuid } from "helpers";
import { holdBooking, purchaseProduct } from "api";
import { useTranslation } from "react-i18next";
import headerImg from "assets/checkout-img.jpg";
import { format, isValid } from "date-fns";
import { AppContext } from "App";
import _ from "lodash";
// MUI
import { Box, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// Components
import SectionHeaderCard from "components/Cards/SectionHeaderCard";
import SuccessPurchaseDialog from "./SuccessPurchaseDialog";
import ViatorCheckoutForm from "../form/ViatorCheckoutForm";
import PrimaryButton from "ui/base/button/PrimaryButton";
import ScrollablePage from "components/ScrollablePage";
import { FieldRenderer } from "../form/FieldRenderer";
import BackButton from "ui/base/button/BackButton";
import SummaryCard from "../card/SummaryCard";
import WarningTag from "ui/base/tag/WarningTag";
import LinkButton from "ui/base/button/LinkButton";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 2.5),
    paddingBottom: 100,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
}));

const ViatorCheckoutDialog = ({
  onClose,
  orderDetails,
  travelDate,
  travelers,
  orderData = {},
  saveProgress,
  product,
  selectedSku = null,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { auth, kc } = useContext(AppContext);
  const scrollToInvalidFields = useRef(false);
  const currentError = useRef(null);
  const paymentSubmitSucceeded = useRef(false);
  const [payment, setPayment] = useState(null);
  const [validData, setValidData] = useState(false);
  const [purchasing, setPurchasing] = useState(false);
  const [validFields, setValidFields] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const [startTimeout, setStartTimeout] = useState(null);
  const [loadingiFrame, setLoadingiFrame] = useState(false);
  const [loadingPaymentSession, setLoadingPaymentSession] = useState(false);
  const [bill, setBill] = useState(null);
  const [errors, setErrors] = useState({});
  const [view, setView] = useState("fields");
  const [checkoutError, setCheckoutError] = useState(null);
  const guestName = kc?.components?.guest?.name ?? "";
  const fullDate = !!travelDate?.value
    ? new Date(`${travelDate.value} ${orderData?.START_TIME?.value ?? "00:00"}`)
    : "";
  const skuId = selectedSku.sku_id.split("#")[1];
  const productHeaderImg = product.header_image;
  const viatorTermsAndCond = t("product_terms_conditions").split("{v_t_c}");

  useEffect(() => {
    let timer = null;
    if (!startTimeout) {
      return;
    }
    timer = setTimeout(() => {
      if (!paymentSubmitSucceeded.current) {
        setPurchasing(false);
        setCheckoutError(currentError.current);
      }
    }, 4000);

    return () => clearTimeout(timer);
  }, [startTimeout]);

  useEffect(() => {
    let newErrors = {};
    let validForm = true;
    _.each(Object.keys(orderData), (o_d) => {
      const f = orderData[o_d];
      newErrors[f.field] = f.required && !f.value;
      if (validForm && f.required && !f.value) {
        validForm = false;
      }
    });
    setErrors(newErrors);
    setValidFields(validForm);
    if (scrollToInvalidFields.current) {
      scrollToInvalidFields.current = false;
      scrollToMissingField();
    }
  }, [orderData]);

  const scrollToBottom = () => {
    const el = document.getElementById("checkout-error");
    if (el) {
      el.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const purchase = async (paymentData) => {
    setCheckoutError(null);
    try {
      const requestFields = [
        {
          field: "HEADER_IMAGE",
          field_type: "image",
          name: "Product Image",
          value: productHeaderImg,
        },
        {
          field: "TRAVELERS",
          field_type: "multi_select",
          name: "Travelers",
          value: travelers,
        },
      ].concat(formatProductFields({ TRAVEL_DATE: travelDate, ...orderData }));
      const response = await purchaseProduct({
        auth: auth,
        sku_id: selectedSku.sku_id,
        booking_id: kc.booking_id,
        fields: requestFields,
        product,
        viator_context: {
          cart_ref: checkoutData?.cartRef,
          booking_ref: checkoutData?.items?.[0]?.bookingRef,
          payment_token: paymentData.paymentToken,
          amount: checkoutData?.totalHeldPrice?.price?.recommendedRetailPrice,
          currency: checkoutData?.currency,
        },
      });
      console.log("PURCHASE SUCCESS", response);
      if (response.data.success) {
        setBill(response.data.guest_bill);
        setPurchasing(false);
      } else {
        let error = response.data.error ?? t("generic_error_msg");
        setCheckoutError(error);
        setLoadingiFrame(true);
        setTimeout(() => {
          setLoadingiFrame(false);
          setPurchasing(false);
        }, 1000);
      }
    } catch (error) {
      console.log("ERROR PURCHASE PRODUCT", error);
      let reason =
        error?.response?.data?.error?.[0]?.message ?? t("generic_error_msg");
      setCheckoutError(reason);
      setPurchasing(false);
    }
  };

  function submitSucceeded(res) {
    // {paymentToken: <Token>, cardData: {cardType:”Visa” cardholderName: "Jane Doe"}}}
    paymentSubmitSucceeded.current = true;
    purchase(res);
  }

  function submitFailed(message) {
    console.log("PAYMENT FAILED", message);
    setPurchasing(false);
  }

  /**
   * The function called when the submit button is pressed.
   */
  const submit = () => {
    setPurchasing(true);
    if (!!checkoutError) {
      paymentSubmitSucceeded.current = false;
      currentError.current = checkoutError;
      setCheckoutError(null);
      setStartTimeout(newUuid());
    }
    const countryRef = document.getElementById("country-select-input");
    const zipCodeRef = document.getElementById("zip-code");
    const country = countryRef?.value;
    const zip = zipCodeRef?.value;
    const cardMetadata = {
      address: {
        country: country,
        postalCode: zip,
      },
    };
    // Call the payment library to submit the card details to the TripAdvisor vault.
    payment
      .submitForm(cardMetadata)
      .then((resp) => submitSucceeded(resp))
      .catch((err) => submitFailed(err));
  };

  const onFieldChange = (e) => {
    const newProgressData = _.cloneDeep(orderData);
    newProgressData[e.field] = !!orderData[e.field]
      ? { ...orderData[e.field], value: e.value, error: !!e.error }
      : e;
    saveProgress(newProgressData);
  };

  const scrollToMissingField = () => {
    const firstErrorEl = document.getElementsByClassName("-error")?.[0];
    if (!!firstErrorEl) {
      firstErrorEl.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const validateForm = () => {
    const newData = {};
    _.each(Object.keys(orderData), (fieldKey) => {
      newData[fieldKey] = { ...orderData[fieldKey], error: errors[fieldKey] };
    });
    scrollToInvalidFields.current = true;
    saveProgress(newData);
    return validFields;
  };

  const createPaymentSession = () => {
    setCheckoutError(null);
    setPurchasing(false);
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    setLoadingPaymentSession(true);
    let productPromise = holdBooking({
      auth,
      product: orderDetails.product.product_id,
      optionCode: skuId,
      travelDate,
      startTime: orderData?.START_TIME?.value,
      travelers: travelers,
    });
    productPromise
      .then((response) => {
        if (response.data) {
          console.log("Checkout data: ", response.data);
          setCheckoutData(response.data);
          setView("payment");
        }
        setLoadingPaymentSession((prev) => false);
      })
      .catch((error) => {
        scrollToBottom();
        let reason =
          error?.response?.data?.error?.[0]?.message ?? t("generic_error_msg");
        setCheckoutError(reason);
        setLoadingPaymentSession((prev) => false);
      });
  };

  const header = (
    <SectionHeaderCard
      disableGrayscale
      title={t("checkout-title")}
      img={headerImg}
    />
  );

  const actionPanel = (
    <div>
      {view === "payment" ? (
        <PrimaryButton
          label={t("book_now")}
          disabled={!validData || purchasing}
          loading={purchasing}
          onClick={submit}
        />
      ) : (
        <PrimaryButton
          label={t("continue")}
          disabled={loadingPaymentSession}
          loading={loadingPaymentSession}
          onClick={createPaymentSession}
        />
      )}
    </div>
  );

  const content = (
    <div className={classes.container}>
      <BackButton
        onClick={() => (view === "fields" ? onClose() : setView("fields"))}
      />
      <SummaryCard
        product={orderDetails.product.name}
        image={orderDetails.product.header_image}
        date={
          isValid(fullDate)
            ? !!orderData?.START_TIME?.value
              ? format(fullDate, "E, PP • p")
              : format(fullDate, "E, PP")
            : ""
        }
        persons={orderDetails.travelers}
        total={getCurrency({
          price:
            checkoutData?.totalHeldPrice?.price?.recommendedRetailPrice ??
            orderDetails.price,
          currency: checkoutData?.currency ?? orderDetails.currency,
        })}
      />
      {view === "fields" ? (
        <>
          {selectedSku?.enter_details?.map((item, idx) => {
            let addSpacing = false;
            if (
              idx > 0 &&
              selectedSku.enter_details[idx - 1].field.includes("#") &&
              !item.field.includes("#")
            ) {
              addSpacing = true;
            }
            return (
              <>
                {addSpacing && <Box height={16} width={"100%"} />}
                <FieldRenderer
                  key={idx}
                  index={idx}
                  currentFields={orderData}
                  item={orderData[item.field] ?? item}
                  setMultipleFields={(newData) => saveProgress(newData)}
                  callback={onFieldChange}
                />
              </>
            );
          })}
        </>
      ) : (
        <>
          {loadingiFrame ? (
            <Skeleton width={"100%"} height={400} sx={{ transform: "none" }} />
          ) : (
            <ViatorCheckoutForm
              name={guestName}
              setValidData={setValidData}
              setPayment={setPayment}
              paymentSessionToken={checkoutData.paymentSessionToken}
            />
          )}
          <Typography fontSize={12}>
            {viatorTermsAndCond[0]}
            <LinkButton
              label={viatorTermsAndCond[1]}
              component="a"
              target="_blank"
              href={"https://www.viator.com/support/termsAndConditions"}
              rel="noopener noreferrer"
              sx={{ fontSize: "12px", padding: 0 }}
            />
            {viatorTermsAndCond[2]}
          </Typography>
        </>
      )}
      {!!checkoutError && <WarningTag label={checkoutError} />}
      <div id="checkout-error" />
    </div>
  );

  return (
    <>
      <SuccessPurchaseDialog open={!!bill} bill={bill} />
      <ScrollablePage
        actionPanel={actionPanel}
        onClose={onClose}
        header={header}
        pageContent={content}
      />
    </>
  );
};

export default ViatorCheckoutDialog;
