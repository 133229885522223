import React from "react";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { alpha, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import LinkButton from "../button/LinkButton";

const Card = styled(Stack)(({ theme }) => ({
  border: `1.5px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: "10px",
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}));

const InfoCard = ({ text, action }) => {
  return (
    <Card flexDirection={"row"} justifyContent={"flex-start"}>
      <InfoIcon sx={{ fontSize: 18, color: "primary.main" }} />
      <Typography flex={1} lineHeight={"22px"}>
        {text}
        {!!action && (
          <LinkButton label={action.label} onClick={action.onClick} />
        )}
      </Typography>
    </Card>
  );
};

export default InfoCard;
