import React from "react";
import styled from "@emotion/styled";
import { alpha, Stack } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const CustomTag = styled(Stack)(({ theme, variant }) => ({
  borderRadius: "10px",
  padding: theme.spacing(0.5, 1),
  backgroundColor:
    variant === "main"
      ? theme.palette.primary.contrastLight
      : variant === "mainLight"
      ? alpha(theme.palette.primary.contrast, 0.2)
      : "#000000",
  color:
    variant === "main"
      ? theme.palette.primary.main
      : variant === "mainLight"
      ? theme.palette.primary.contrast
      : "#FFFFFF",
}));

const WarningTag = ({ label, variant = "main", ...otherProps }) => {
  return (
    <CustomTag
      gap={1}
      variant={variant}
      flexDirection={"row"}
      alignItems={"center"}
      {...otherProps}
    >
      <WarningRoundedIcon />
      {label}
    </CustomTag>
  );
};

export default WarningTag;
