import React, { useContext } from 'react';
import {
  Button,
  Typography,
  Link,
  Dialog,
  CircularProgress,
  Box,
} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { asyncLoop } from 'helpers';
import { uploadImage } from 'api';
// components
import BrandingFooter from 'components/BrandingFooter.jsx';
import CollectDetailsInput from 'components/CollectDetailsInput';
import { AppContext } from 'App';


const useStyles = makeStyles(theme => ({
  loader: { color: theme.palette.primary.contrast },
  container: {
    padding: theme.spacing(0, 2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    paddingTop: theme.spacing(5),
  },
  submitBtn: {
    borderRadius: '12px !important',
    fontSize: '22px !important',
    padding: '10px !important',
    marginBottom: '20px !important',
    color: theme.palette.primary.contrast + '!important',
  },
}))


export default function DetailsPage({ submitDetails, setDetails, setView, userCountryCode, setUserCountryCode }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [phone, setPhone] = React.useState('')
  const { kc } = useContext(AppContext)
  const [initialPhoneCode, setInitialPhoneCode] = React.useState('')
  const [initialCountry, setInitialCountry] = React.useState('')
  const [imagesToUpload, setImagesToUpload] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [disableSave, setDisableSave] = React.useState(false)
  let components = kc?.components || {}

  React.useEffect(() => {
    const phone_ind = components.details?.findIndex(el => el.field === 'guest_phone')
    const guest_phone = components.details[phone_ind]
    if (!!guest_phone?.value) {
      setInitialPhoneCode(guest_phone.value[0])
      setPhone(`${guest_phone.value[0]}${guest_phone.value[1]}`)
      const fields = Object.assign([], components.details)
      fields[phone_ind].value = `+${guest_phone.value[0]}${guest_phone.value[1]}`
      setDetails(fields)
      if (!guest_phone?.value?.[0] || guest_phone?.value?.[0] === '+') {
        if (!!userCountryCode) {
          setInitialCountry(prev => userCountryCode)
        } else {
          setInitialCountry(prev => 'us')
          handleCountryCodeDetection()
        }
      }
    }
  }, [])


  const uploadImages = async () => {
    let urls = {}
    await asyncLoop(Object.keys(imagesToUpload), async (k) => {
      urls[k] = await uploadImage(imagesToUpload[k].result)
    })

    let newFields = [...components.details]
    Object.keys(urls).forEach(urlKey => {
      newFields[urlKey].value = urls[urlKey]
    })
    setDetails(newFields)
    setImagesToUpload({})
    setLoading(false)
    submitDetails()
  }

  const handleSubmit = () => {
    const errors = (components.details ?? []).some(f => !!f.error)
    setDisableSave(true)
    if (!!Object.keys(imagesToUpload).length && !errors) {
      setLoading(true)
      uploadImages()
    } else {
      submitDetails(() => { 
        setDisableSave(false) 
      })
    }
  }

  const handleCountryCodeDetection = () => {
    const endpoint = 'https://ipapi.co/country/'
    fetch(endpoint)
      .then(response => {
        response.text().then(txt => {
          const newUserCountryCode = txt.toLowerCase()
          setInitialCountry(prev => newUserCountryCode)
          setUserCountryCode(newUserCountryCode)
        })
      })
      .catch(err => console.log("Country detection failed: " + err))
  }


  return <>
    <Dialog open={!!loading} maxWidth='xs' disableEscapeKeyDown>
      <Box p={5} bgcolor={theme => theme.palette.primary.main}>
        <CircularProgress className={classes.loader} />
      </Box>
    </Dialog>
    <main>
      <div className={classes.container}>
        <Button
          style={{ padding: 0, width: 'fit-content' }}
          onClick={() => { setView("verify") }}
          startIcon={<ArrowBackIosNewIcon />}
        >{t("back")}</Button>
        <Box mb={1.5}>
          <Typography variant='h3'>{t("enter_details")}</Typography>
        </Box>
        {components.details.map((f, ind) =>
          <div key={ind}>
            <CollectDetailsInput
              field={f}
              index={ind}
              components={components}
              setDetails={setDetails}
              imagesToUpload={imagesToUpload}
              setImages={setImagesToUpload}
              phoneProps={{ phone, initialPhoneCode, initialCountry, setPhone }}
            />
          </div>
        )}
        <Typography>
          {t("privacy_policy_disclaimer")}
          <Link target='_blank' rel='noreferrer noopener' href='https://ensoconnect.com/privacy-policy/'>{t("privacy_policy")}</Link>
        </Typography>
        <Button id="submitButton" className={classes.submitBtn} disabled={disableSave} onClick={handleSubmit} fullWidth size="large" variant="contained">
          {t("submit")}
        </Button>
      </div>
    </main>
    <BrandingFooter metadata={components?.metadata ?? { color1: '#FFFFFF', color2: '#000000' }} />
  </>
}