import React from "react";
import { Button } from "@mui/material";

const LinkButton = ({ label, onClick, sx = {}, ...buttonProps }) => {
  return (
    <Button
      onClick={onClick}
      disableRipple
      sx={{
        textDecoration: "underline !important",
        fontWeight: 500,
        fontSize: 16,
        ...sx,
      }}
      {...buttonProps}
    >
      {label}
    </Button>
  );
};

export default LinkButton;
