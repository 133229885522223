import React from "react";
import { Stack } from "@mui/material";

export default function CustomRow({ children, ...otherProps }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      {...otherProps}
    >
      {children}
    </Stack>
  );
}
