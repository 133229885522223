import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { AppContext } from "App";
// UI & components
import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";
import { Stack, SvgIcon, Typography } from "@mui/material";
import PrimaryButton from "ui/base/button/PrimaryButton";
import OutlinedButton from "ui/base/button/OutlinedButton";
import Modal from "components/Modal";

export default function SuccessPurchaseDialog({ open, bill }) {
  const { t } = useTranslation();
  const { auth } = useContext(AppContext);
  const { listing_id } = useParams();
  const navigate = useNavigate();
  const isPending = bill?.status === "unpaid";
  const hasVoucher = !!bill?.voucher?.url;
  const successMsg = isPending
    ? t("success_dialog_pending_title")
    : t("success_dialog_available_title");

  const goToUpgrades = () => {
    let path = !!listing_id
      ? `/${listing_id}/upgrades`
      : `/upgrades/?auth=${auth}`;
    navigate(path, { replace: true, state: { closeProduct: true } });
  };

  if (!open) {
    return null;
  }
  return (
    <Modal
      hideX
      open={open}
      setOpen={() => {}}
      disableEscape
      customBGColor={"#FFFFFF"}
      text={
        <Stack flexDirection={"column"}>
          <DotLottieReact
            src="https://lottie.host/c85f2d02-be87-4096-a3e8-531924b14bb9/bYaxkifB9Z.lottie"
            autoplay
          />
          <Typography
            variant="h2"
            fontSize={20}
            sx={{ color: "#000000", mb: 4 }}
          >
            {successMsg}
          </Typography>
          {hasVoucher && (
            <PrimaryButton
              label={t("see_voucher")}
              variant="custom"
              component="a"
              target="_blank"
              href={bill.voucher.url}
              rel="noopener noreferrer"
              endIcon={
                <SvgIcon
                  component={ExternalLinkIcon}
                  viewBox="0 0 17 16"
                  sx={{ fontSize: "18px !important" }}
                />
              }
              customColors={{
                bg: "#000000",
                color: "#FFFFFF",
              }}
            />
          )}
          {hasVoucher ? (
            <OutlinedButton
              variant="custom"
              label={t("return_upgrades")}
              onClick={goToUpgrades}
              sx={{
                border: "2px solid #000000 !important",
                fontWeight: "600 !important",
                color: "#000000",
                mt: 2.5,
              }}
            />
          ) : (
            <PrimaryButton
              label={t("return_upgrades")}
              onClick={goToUpgrades}
              variant="custom"
              customColors={{
                bg: "#000000",
                color: "#FFFFFF",
              }}
            />
          )}
        </Stack>
      }
    />
  );
}
