import React from "react";
import styled from "@emotion/styled";
import BaseInputLabel from "./InputLabel";
import { FormControl, InputBase, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningTag from "../tag/WarningTag";

const CustomizedInput = styled(InputBase)(({ theme, styleProps }) => ({
  // Hide browser's default clock icon
  "& input[type='time']::-webkit-calendar-picker-indicator, input[type='date']::-webkit-calendar-picker-indicator":
    {
      display: "none",
      WebkitAppearance: "none",
    },
  "& input[type='time']::-ms-clear, input[type='date']::-ms-clear": {
    display: "none",
  },
  "& input[type='time'], input[type='date']": { MozAppearance: "textfield" },
  // Input styles
  "& .MuiInputBase-input": {
    borderRadius: styleProps?.borderRadius ?? 10,
    position: "relative",
    backgroundColor:
      styleProps?.backgroundColor ?? theme.palette.primary.contrast,
    border: "1px solid",
    borderColor: styleProps?.borderColor ?? theme.palette.primary.main,
    padding: theme.spacing(1.5),
    color: `${styleProps?.color ?? theme.palette.primary.main} !important`,
    "&:focus": {
      borderRadius: styleProps?.borderRadius ?? 10,
      borderColor: styleProps?.focusBorderColor ?? theme.palette.primary.main,
    },
  },
}));

const BaseInput = ({
  id = "custom-input",
  label,
  placeholder = label,
  fullWidth,
  error,
  required,
  disableLabel,
  customErrorLabel,
  styleProps,
  inputRef,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const errorLabel = customErrorLabel ?? t("please_fill_in");

  return (
    <FormControl
      variant="standard"
      fullWidth={fullWidth}
      className={!!error ? "-error" : ""}
    >
      {!disableLabel && (
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={1}
          rowGap={0.5}
          columnGap={2}
          sx={{ flexFlow: "wrap" }}
        >
          <BaseInputLabel
            styleProps={styleProps}
            htmlFor={id}
            flex={1}
            sx={{ minWidth: "fit-content" }}
          >
            {`${label}${required ? " *" : ""}`}
          </BaseInputLabel>
          {!!error && <WarningTag label={errorLabel} />}
        </Stack>
      )}
      <CustomizedInput
        id={id}
        inputRef={inputRef}
        placeholder={placeholder}
        styleProps={styleProps}
        {...otherProps}
      />
    </FormControl>
  );
};

export default BaseInput;
