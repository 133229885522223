import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
// MUI
import {
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Typography,
} from "@mui/material";
import ClockIcon from "@mui/icons-material/Schedule";
import { makeStyles } from "@mui/styles";

import PhoneComponent from "components/PhoneComponent";
import LanguageSelector from "components/LanguageSelector";
import AddressAutocomplete from "components/AddressAutocomplete";
import MultiOptionSelector from "./MultiOptionSelector";
import UploadImageField from "./UploadImageField";
import TravelerSelector from "../ui/component/product/input/TravelerSelector";
import CustomDatePicker from "../ui/base/input/CustomDatePicker";
import BaseInput from "ui/base/input/BaseInput";

const useStyles = makeStyles((theme) => ({
  checkbox: { color: theme.palette.primary.main },
  timePicker: {
    "& > label": {
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.primary.contrast,
    },
    "& fieldset": {
      border: "1.5px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 12,
    },
  },
}));

export default function CollectDetailsInput({
  field,
  index,
  setDetails,
  phoneProps,
  components,
  imagesToUpload,
  setImages,
}) {
  const { phone, initialPhoneCode, initialCountry, setPhone } =
    phoneProps || {};
  const classes = useStyles();
  const { t } = useTranslation();
  const timeInputRef = useRef(null);
  const timePickerOpen = useRef(false);

  function handleChangeValue(i, value, fieldError = false) {
    const missingField =
    field?.field_type === "number"
    ? value == null
    : Array.isArray(value)
        ? !value.length
        : !value;
        if (!components) {
          let update = {
            value: value,
            name: field?.name,
        field_type: field?.field_type,
        field: field?.field,
        error: !!field?.required ? missingField || fieldError : fieldError,
      };

      setDetails(update);
      return;
    }

    let fields = Object.assign([], components.details);
    fields[i].value = value;
    fields[i].error = !!field?.required
      ? missingField || fieldError
      : fieldError;
    setDetails(fields);
  }

  const onPhoneChange = (i, newPhone) => {
    setPhone(newPhone);
    const fields = Object.assign([], components.details);
    fields[i].value = `+${newPhone}`;
    fields[i].error = false;
    setDetails(fields);
  };

  const handleImageChange = (i, newfile) => {
    let newImages = { ...imagesToUpload, [index]: newfile };
    const fields = Object.assign([], components.details);
    fields[i].error = false;
    setImages(newImages);
  };

  const getFieldTranslation = () => {
    switch (field.field) {
      case "guest_travel_reason":
        return t("travel_reason");
      case "guest_first_name":
        return t("first_name");
      case "guest_last_name":
        return t("last_name");
      case "guest_dob":
        return t("date_of_birth");
      case "num_pets":
        return t("num_of_pets");
      case "guest_email":
        return t("email");
      case "checkin_time":
        return t("checkin_time");
      case "checkout_time":
        return t("checkout_time");
      default:
        return field.name;
    }
  };

  const handleTimeInputClick = () => {
    if (!timePickerOpen.current) {
      timeInputRef.current.showPicker();
      timePickerOpen.current = true;
    } else {
      timePickerOpen.current = false;
    }
  };

  function getFieldByType() {
    switch (field.field_type) {
      case "traveler_input":
        return (
          <TravelerSelector
            field={field}
            onClose={(e, error) => handleChangeValue(index, e, error)}
          />
        );
      case "image":
        return (
          <UploadImageField
            field={field}
            index={index}
            onChange={(newFile) => handleImageChange(index, newFile)}
          />
        );
      case "checkbox":
        return (
          <FormControlLabel
            label={getFieldTranslation()}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={field.value}
                onChange={(e) => handleChangeValue(index, e.target.checked)}
              />
            }
          />
        );
      case "multi_select":
      case "single_select":
        return (
          <MultiOptionSelector
            field={field}
            index={index}
            onChange={handleChangeValue}
            multiple={field.field_type === "multi_select"}
          />
        );
      case "number":
        let numberLabel = getFieldTranslation();
        return (
          <BaseInput
            fullWidth
            required={field.required}
            id={`field-${field.field}`}
            value={field.value}
            label={numberLabel}
            error={field.error}
            placeholder={numberLabel}
            type="number"
            inputProps={{
              inputMode: "numeric",
              min: 0,
              max: field.field === "num_pets" ? 20 : 1000,
            }}
            onChange={(e) => handleChangeValue(index, e.target.value)}
          />
        );
      case "date":
        return (
          <CustomDatePicker
            field={field}
            index={index}
            handleChangeValue={handleChangeValue}
          />
        );
      case "time":
        let timeLabel = getFieldTranslation();
        return (
          <BaseInput
            fullWidth
            id={`field-${field.field}`}
            value={field.value}
            label={timeLabel}
            required={field.required}
            error={field.error}
            placeholder={timeLabel}
            type="time"
            inputRef={timeInputRef}
            inputProps={{ step: 60 }}
            onClick={handleTimeInputClick}
            onBlur={() => {
              timePickerOpen.current = false;
            }}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ position: "absolute", right: 12 }}
              >
                <ClockIcon sx={{ color: "primary.main" }} />
              </InputAdornment>
            }
            onChange={(e) => {
              let newTime = e.target.value;
              handleChangeValue(index, newTime);
            }}
          />
        );

      case "text":
      default:
        const label = getFieldTranslation();
        return (
          <BaseInput
            fullWidth
            id={`field-${field.field}`}
            value={field.value}
            label={label}
            required={field.required}
            error={field.error}
            placeholder={label}
            onChange={(e) =>
              handleChangeValue(
                index,
                e.target.value,
                !!field.required && !e.target.value?.trim()
              )
            }
          />
        );
    }
  }

  function getField() {
    if (field.field.includes("AGEBAND#")) {
      return (
        <Typography fontSize={22} mt={2}>
          {`${t("traveler")} ${field.field.split("#")[1]} (${field.value})`}
        </Typography>
      );
    }

    switch (field.field) {
      case "guest_language":
        return (
          <LanguageSelector
            f={field}
            ind={index}
            onChange={handleChangeValue}
          />
        );
      case "guest_address":
        return (
          <AddressAutocomplete
            address={field.value || {}}
            error={field.error}
            required={field.required}
            onChange={(e, hasErrors) => handleChangeValue(index, e, hasErrors)}
          />
        );
      case "guest_phone":
        return (
          <PhoneComponent
            phone={phone}
            country={initialCountry}
            required={field.required}
            initialCode={initialPhoneCode}
            error={field.error}
            label={t("phone_number")}
            onChange={(val) => onPhoneChange(index, val)}
          />
        );

      default:
        return getFieldByType();
    }
  }

  return getField();
}
