import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isIOS, isMacOs } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AppContext } from "App";
// UI
import { makeStyles } from "@mui/styles";
import { Box, Modal, Skeleton, Typography } from "@mui/material";
// Custom
import StripePage from "components/StripePage";
import StandardProductDialog from "../product/dialog/StandardProductDialog";
import ProductCarousel from "../product/carousel/ProductCarousel";
import UpsellsList from "../product/list/UpsellList";
import PrimaryButton from "ui/base/button/PrimaryButton";

const carouselItems = 8;
const previewItems = 11;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: (props) => (!!props.preview ? theme.spacing(0, -2.5) : 0),
  },
}));

const Upsells = ({ preview = false, upsellsRef }) => {
  const classes = useStyles({ preview });
  const { kc, auth, setView, setHideNavbar, loadingKeycard, loadingProducts } =
    useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { listing_id, upgrade_id } = useParams();
  const { t } = useTranslation();
  const [upsells, setUpsells] = useState({ list: [], carousel: [] });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productLink, setProductLink] = useState(null);
  const [stripeAccount, setStripeAccount] = useState(null);
  let components = kc?.components ?? {};
  let totalUpsells = upsells.carousel.length + upsells.list.length;

  useEffect(() => {
    if (!!upgrade_id && !selectedProduct) {
      let newProduct = components.upsells?.find(
        (up) => up.product_id === upgrade_id
      );
      if (!!newProduct) {
        setSelectedProduct(newProduct);
        setHideNavbar(true);
      }
    } else if (upgrade_id == null && !!selectedProduct) {
      handleCloseProduct(true);
    }
  }, [upgrade_id]);

  useEffect(() => {
    if (!kc) {
      return;
    }
    const newUpsells = [];
    let data = components.upsells || [];
    data.forEach((up) => {
      if (up.url === "payment_link" && !up.purchased) {
        newUpsells.push(up);
      }
    });
    setUpsells({
      carousel: newUpsells.slice(0, carouselItems),
      list: newUpsells.slice(carouselItems),
    });
  }, [kc]);

  const openUrl = (product) => {
    if (product.url.startsWith("sms")) {
      if (isIOS || isMacOs) {
        setProductLink((prev) => product.url);
      } else {
        setProductLink((prev) => product.url.replace("&", "?"));
      }
    } else if (product.url === "payment_link") {
      let upgradesPath = location.pathname.includes("/upgrades")
        ? "/upgrades"
        : "/upgrade";
      let path = !!listing_id
        ? `/${listing_id}${upgradesPath}/${product.product_id}`
        : `${upgradesPath}/${product.product_id}/?auth=${auth}`;
      navigate(path);
    } else {
      setProductLink((prev) => product.url);
    }
  };

  const goToUpgrades = () => {
    let path = !!listing_id
      ? `/${listing_id}/upgrades`
      : `/upgrades/?auth=${auth}`;
    navigate(path, { state: { section: "upsells" } });
  };

  const handleCloseProduct = (replace) => {
    setSelectedProduct((prev) => null);
    setHideNavbar(false);
    setView(kc.view);
    let upgradesPath = location.pathname.includes("/upgrades")
      ? "/upgrades"
      : "";
    let path = !!listing_id
      ? `/${listing_id}${upgradesPath}`
      : `${upgradesPath}/?auth=${auth}`;
    navigate(path, { replace: !!replace });
  };

  if (!loadingProducts && !components.upsells?.length) {
    return null;
  }
  return (
    <div className={classes.container}>
      {(!!selectedProduct || !!productLink) && (
        <Modal open>
          <div>
            {!!productLink ? (
              <StripePage
                link={productLink}
                stripeAccount={stripeAccount}
                onClose={() => setProductLink((prev) => null)}
              />
            ) : (
              <StandardProductDialog
                product={selectedProduct}
                productType={"up"}
                setStripeAccount={setStripeAccount}
                onPaymentRedirect={(link) => setProductLink((prev) => link)}
                onClose={handleCloseProduct}
              />
            )}
          </div>
        </Modal>
      )}
      <Box py={1.5} px={2.5} ref={upsellsRef}>
        {!!loadingProducts ? (
          <Skeleton
            height={"24px"}
            width={"300px"}
            sx={{ transform: "none", mb: 1 }}
          />
        ) : (
          <Typography variant="body2">
            {t("upsell_list_title").toUpperCase()}
          </Typography>
        )}
      </Box>
      <ProductCarousel
        upsells={upsells.carousel}
        loading={loadingProducts || loadingKeycard}
        onItemClick={openUrl}
      />
      <UpsellsList
        upsells={upsells.list}
        limit={preview ? previewItems - upsells.carousel.length : totalUpsells}
        loading={loadingProducts || loadingKeycard}
        onItemClick={openUrl}
      />
      {preview && totalUpsells > previewItems && (
        <Box mt={1.5} px={2.5}>
          <PrimaryButton
            variant="transparent"
            onClick={() => goToUpgrades()}
            label={`${t("view")} ${totalUpsells - previewItems} ${t("more")}`}
          />
        </Box>
      )}
    </div>
  );
};

export default Upsells;
