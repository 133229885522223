import React, { useContext, useMemo } from "react";
import { languageEnum } from "configuration/specs";
import BaseSelector from "ui/base/input/BaseSelector";
import { AppContext } from "App";

const languages = Object.keys(languageEnum);
const navigatorLang = (navigator.language || navigator.userLanguage || "en")
  .toLowerCase()
  .substring(0, 2);

const PREFERRED_LANGUAGES = ["en", "fr", "de", "it", "pl", "es", "sv"];

export default function LanguageSelector({ f, ind, onChange }) {
  const { kc } = useContext(AppContext);
  const userLanguage = kc?.components?.guest?.language || navigatorLang;
  const { options, topOptions } = useMemo(() => {
    const newTopOptions = PREFERRED_LANGUAGES.includes(userLanguage)
      ? PREFERRED_LANGUAGES
      : [userLanguage, ...PREFERRED_LANGUAGES];
    return {
      options: languages
        .filter((l) => l !== userLanguage && !PREFERRED_LANGUAGES.includes(l))
        .map((l) => ({ value: l, label: languageEnum[l] })),
      topOptions: newTopOptions.map((l) => ({
        value: l,
        label: languageEnum[l],
      })),
    };
  }, [kc]);

  return (
    <BaseSelector
      fullWidth
      label={`${f.name}${!!f.required ? " *" : ""}`}
      id="language-selector"
      error={!!f.error && !!f.required}
      value={f.value}
      defaultOption={f.name}
      onChange={(e) => onChange(ind, e.target.value)}
      options={options}
      topOptions={topOptions}
    />
  );
}
