import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BaseInputLabel from "../input/InputLabel";
import WarningTag from "../tag/WarningTag";

const MenuButton = ({
  id = "menu-button",
  fullWidth,
  label,
  error,
  required,
  customErrorLabel,
  empty,
  value = "",
  onClick,
}) => {
  const { t } = useTranslation();
  const labelId = `${id}-label`;
  const errorLabel = customErrorLabel ?? t("please_select");

  return (
    <Stack gap={1} flexDirection={"column"} className={!!error ? "-error" : ""}>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        rowGap={0.5}
        columnGap={2}
        flexWrap={"wrap"}
      >
        <BaseInputLabel id={labelId}>{`${label}${
          required ? " *" : ""
        }`}</BaseInputLabel>
        {!!error && <WarningTag label={errorLabel} />}
      </Stack>

      <Button
        disableRipple
        fullWidth={fullWidth}
        onClick={onClick}
        endIcon={<ExpandMoreIcon />}
        sx={{
          fontFamily: '"Montserrat", serif',
          color: "primary.main",
          borderRadius: "10px",
          height: 50,
          opacity: !!empty ? 0.5 : 1,
          backgroundColor: "primary.contrast",
          fontSize: 16,
          borderColor: "primary.main",
          borderStyle: "solid",
          borderWidth: "1.5px",
          justifyContent: "space-between",
        }}
      >
        {value}
      </Button>
    </Stack>
  );
};

export default MenuButton;
