import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const CustomIconButton = styled(IconButton)(({ theme, customBGColor }) => ({
  backgroundColor: customBGColor ?? theme.palette.primary.contrast,
}));

const XIcon = styled(CloseIcon)(({ theme, customXColor }) => ({
  fontSize: 24,
  color: customXColor ?? theme.palette.primary.main,
}));

const CloseButton = ({ onClick }) => {
  return (
    <CustomIconButton onClick={onClick}>
      <XIcon />
    </CustomIconButton>
  );
};

export default CloseButton;
