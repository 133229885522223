import React from "react";
import BaseSelector from "ui/base/input/BaseSelector";

export default function MultiOptionSelector({
  field,
  index,
  onChange,
  multiple,
}) {
  const hasErrors = !!field.error && !!field.required;
  let options = field.field_options?.map((fieldOpt) => {
    return typeof fieldOpt === "string"
      ? { value: fieldOpt, label: fieldOpt }
      : fieldOpt;
  });

  return (
    <BaseSelector
      fullWidth
      multiple={multiple}
      required={field.required}
      label={field.name}
      id={`field-${field.field}`}
      error={hasErrors}
      value={
        !!multiple
          ? Array.isArray(field.value)
            ? field.value
            : []
          : field.value
      }
      defaultOption={field.name}
      onChange={(e) => onChange(index, e.target.value)}
      options={options}
    />
  );
}
