import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  "&.MuiListItem-root": {
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 0,
    "&.disabled": {
      border: "none",
      background: theme.palette.action.disabledBackground,
    },
    "&.selected": {
      border: "none",
      background: theme.palette.action.hover,
    },
  },
}));

const PriceLabel = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    borderRadius: 5,
    padding: theme.spacing(0.5, 1),
    background: theme.palette.primary.main,
    color: `${theme.palette.getContrastText(theme.palette.primary.main)}`,
  },
}));

const SelectedOptionItem = ({ name, price, selectedOption }) => {
  const { t } = useTranslation();

  return (
    <CustomListItem
      disablePadding
      className={selectedOption === null ? "disabled" : "selected"}
    >
      <ListItemButton disableRipple role={undefined} dense>
        <ListItemText
          disableTypography
          primary={
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                sx={{ flex: 1, paddingRight: 1 }}
                textAlign={selectedOption === null ? "center" : "left"}
              >
                {name || t("nothing_selected")}
              </Typography>
              {selectedOption !== null && <PriceLabel>{price}</PriceLabel>}
            </Stack>
          }
        />
      </ListItemButton>
    </CustomListItem>
  );
};

export default SelectedOptionItem;
