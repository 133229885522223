import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { searchProducts } from "api";
import { AppContext } from "App";
import clsx from "clsx";
// UI
import { makeStyles } from "@mui/styles";
import { Box, Modal, Skeleton, Typography } from "@mui/material";
import VirtualizedList from "ui/base/list/VirtualizedList";
import ProductCard from "ui/component/product/card/ProductCard";
import ButtonTag from "ui/base/tag/ButtonTag";
import Carousel from "ui/base/carousel/Carousel";
import ViatorProductDialog from "../dialog/ViatorProductDialog";
import PrimaryButton from "ui/base/button/PrimaryButton";
import BackButton from "ui/base/button/BackButton";
import { formatProduct, newUuid } from "helpers";

const previewItems = 4;
const defaultLang = "en";
const productCardHeight = 116;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: (props) => theme.spacing(3, !!props.preview ? -2.5 : 0, 0),
    padding: theme.spacing(0, 2.5),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&.disableGutters": { padding: 0 },
    "&.fullSize": { overflow: "auto" },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    flex: 1,
  },
  carouselWrapper: {
    position: "relative",
    padding: 0,
    marginBottom: theme.spacing(2.5),
  },
  backBtnRow: { backgroundColor: theme.palette.primary.contrast },
  stickyRow: {
    top: -1,
    position: "sticky",
    backgroundColor: theme.palette.primary.contrast,
    zIndex: 1,
  },
}));

const ProductList = ({
  preview = false,
  sectionRef,
  initialData = { initialTag: null, initialProducts: [] },
  saveInitialData,
  disableGutters,
}) => {
  const classes = useStyles({ preview });
  const { listing_id } = useParams();
  // FOR QA TESTING ONLY - START
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("product_id");
  // FOR QA TESTING ONLY - END
  const navigate = useNavigate();
  const location = useLocation();
  const { kc, auth, setHideNavbar, loadingProducts } = useContext(AppContext);
  const { t } = useTranslation();
  const carouselRef = useRef(null);
  const searchProductsRequestId = useRef(null);
  const initialTagSelected = useRef(
    !!initialData.initialTag || !!location?.state?.selectedTag
  );
  const [loadingFilteredList, setLoadingFilteredList] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(
    initialData.initialProducts ?? []
  );
  const [selectedTagID, setSelectedTagID] = useState(
    initialData.initialTag ?? null
  );
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const isUpgradesPage = location?.pathname?.includes("upgrades");
  const components = kc?.components ?? {};
  const language = components.guest?.language ?? defaultLang;
  const products = components.viator?.products || [];
  const tags = components.viator?.tags || [];
  const productList = !!filteredProducts.length
    ? filteredProducts
    : !!preview
    ? products.slice(0, previewItems)
    : products;
  const remainingItems =
    (selectedTagID === null ? products.length : productList.length) -
    previewItems;
  const place =
    components.listing?.line3 ||
    components.listing?.line2 ||
    components.listing?.line1 ||
    "this place";
  const listMinHeight =
    (loadingProducts || loadingFilteredList
      ? previewItems
      : productList.length) * productCardHeight;

  useEffect(() => {
    // FOR QA TESTING ONLY - START
    if (!!productId) {
      setHideNavbar(true);
      setSelectedProduct({ product_id: productId });
    }
    // FOR QA TESTING ONLY - END
    if (location?.state?.selectedTag) {
      onFilterClick(location.state.selectedTag);
      navigate(`${location.pathname}${location.search}`, {
        state: {
          ...location.state,
          selectedTag: null,
        },
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!!location?.state?.closeProduct) {
      setSelectedProduct(null);
      navigate(`${location.pathname}${location.search}`, {
        state: {
          ...location.state,
          closeProduct: false,
        },
        replace: true,
      });
    }
  }, [location]);

  useEffect(() => {
    if (!initialTagSelected.current && tags.length) {
      initialTagSelected.current = true;
      onFilterClick(tags[0]);
    }
  }, [tags]);

  useEffect(() => {
    if (!!initialData.initialTag) {
      scrollToTag(initialData.initialTag);
    }
  }, []);

  const scrollToTag = (tagId) => {
    if (carouselRef.current && tagId) {
      const tagEl = carouselRef.current.getItemElementById(tagId);
      if (tagEl) {
        carouselRef.current.scrollToItem(tagEl, "smooth", "center");
      }
    }
  };

  const loadMoreItems = useCallback(() => {
    if (isNextPageLoading || selectedTagID === null) return;

    setIsNextPageLoading(true);

    return searchProducts({
      auth,
      listing_id,
      booking_id: kc?.booking_id,
      page: page + 1,
      tags: [selectedTagID],
    }).then((r) => {
      let newProducts = r.data?.products ?? [];
      setFilteredProducts((prev) => prev.concat(newProducts));
      setHasNextPage(newProducts > 0);
      setIsNextPageLoading(false);
      setPage((prev) => prev + 1);
    });
  }, [isNextPageLoading, filteredProducts]);

  const goToUpgrades = (tag = null) => {
    let path = !!listing_id
      ? `/${listing_id}/upgrades`
      : `/upgrades/?auth=${auth}`;
    navigate(path, { state: { selectedTag: tag, section: "products" } });
  };

  const handleOpenProduct = (product) => {
    const formattedProduct = formatProduct(product, language);
    setHideNavbar(true);
    setSelectedProduct(formattedProduct);
  };

  const handleCloseProduct = () => {
    setHideNavbar(false);
    setSelectedProduct((prev) => null);
  };

  const onFilterClick = async (tag) => {
    const requestId = newUuid();
    searchProductsRequestId.current = requestId;
    setPage(1);
    if (selectedTagID === tag[1]) {
      setSelectedTagID(null);
      setFilteredProducts([]);
      setLoadingFilteredList(false);
    } else {
      setSelectedTagID(tag[1]);
      scrollToTag(tag[1]);
      setLoadingFilteredList(true);
      try {
        const response = await searchProducts({
          auth,
          listing_id,
          booking_id: kc?.booking_id,
          page,
          tags: [tag[1]],
        });
        if (searchProductsRequestId.current === requestId) {
          setFilteredProducts(() => response.data?.products ?? []);
          setLoadingFilteredList(false);
        }
      } catch (error) {
        if (searchProductsRequestId.current === requestId) {
          setLoadingFilteredList(false);
        }
      }
    }
  };

  function getMultiLangText(array) {
    let lang = components.guest?.language || "en";
    return (
      array.find((d) => d.language === lang)?.value ??
      (array.find((d) => d.language === "en")?.value || "")
    );
  }

  const isItemLoaded = (index) => !hasNextPage || index < productList.length;

  const getItem = ({ index, loading }) => {
    if (loading) {
      return (
        <Skeleton
          animation="wave"
          width={"calc(100% - 40px)"}
          height={142}
          sx={{
            borderRadius: "15px",
            transform: "none",
            mb: 1,
            ml: 2.5,
          }}
        />
      );
    }
    const pr = productList[index];
    let duration = null;
    if (pr.metadata?.duration?.fixedDurationInMinutes != null) {
      duration = pr.metadata?.duration?.fixedDurationInMinutes;
    } else if (pr.metadata?.duration?.variableDurationFromMinutes != null) {
      duration = [
        pr.metadata?.duration?.variableDurationFromMinutes,
        pr.metadata?.duration?.variableDurationToMinutes,
      ];
    }

    return (
      <ProductCard
        key={pr.product_id}
        resource={pr}
        image={pr.header_image}
        title={getMultiLangText(pr.name)}
        lang={components.guest?.language || "en"}
        duration={duration}
        rating={pr.metadata?.reviews?.combinedAverageRating}
        reviews={pr.metadata?.reviews?.totalReviews}
        price={pr.metadata?.pricing?.summary?.fromPrice}
        currency={pr.metadata?.pricing?.currency}
        onClick={() => handleOpenProduct(pr)}
      />
    );
  };

  if (!kc?.viator_enabled || !Object.keys(components?.viator || {}).length) {
    return null;
  }
  return (
    <div
      className={clsx(classes.container, {
        disableGutters: disableGutters,
        fullSize: !preview,
      })}
    >
      {!!selectedProduct && (
        <Modal open>
          <div>
            <ViatorProductDialog
              product={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              onClose={handleCloseProduct}
            />
          </div>
        </Modal>
      )}
      <Box className={clsx({ [classes.stickyRow]: !preview })}>
        {!preview && (
          <Box pt={1.5} pb={1} px={2.5} className={classes.backBtnRow}>
            <BackButton
              onClick={() =>
                saveInitialData(false, selectedTagID, filteredProducts)
              }
            />
          </Box>
        )}
      </Box>
      <Box py={1.5} px={2.5}>
        {loadingProducts ? (
          <Skeleton
            height={"24px"}
            width={"350px"}
            sx={{ transform: "none", mb: 1 }}
          />
        ) : (
          <Typography
            variant="body2"
            component={"div"}
            ref={sectionRef}
            style={{ textTransform: "uppercase", opacity: 1 }}
          >
            <Typography component={"span"} style={{ opacity: 0.5 }}>
              {t("things_to_do")}
            </Typography>
            {` ${place}`}
          </Typography>
        )}
      </Box>

      <Carousel wrapperClass={classes.carouselWrapper} menuRef={carouselRef}>
        {loadingProducts
          ? [...Array(10).keys()].map((el) => (
              <Skeleton
                key={el}
                sx={{
                  transform: "none",
                  ml: el === 0 ? 2.5 : 0,
                  mr: 1.5,
                  borderRadius: 360,
                }}
              >
                <ButtonTag text={"loading tag"} />
              </Skeleton>
            ))
          : tags.map((tag, i) => (
              <ButtonTag
                key={tag[1]}
                itemId={tag[1]}
                text={tag[0]}
                selected={selectedTagID === tag[1]}
                onClick={
                  !!preview && !isUpgradesPage
                    ? () => goToUpgrades(tag)
                    : () => onFilterClick(tag)
                }
                sx={{
                  ml: i === 0 ? 2.5 : 0,
                  mr: i === tags.length - 1 ? 2.5 : 1.5,
                }}
              />
            ))}
      </Carousel>

      <Box className={classes.list} minHeight={preview ? 0 : listMinHeight}>
        {loadingProducts || loadingFilteredList ? (
          [...Array(previewItems).keys()].map((el) => (
            <Skeleton
              key={el}
              animation="wave"
              width={"calc(100% - 40px)"}
              height={108}
              sx={{
                borderRadius: "15px",
                transform: "none",
                mb: 1,
                ml: 2.5,
              }}
            />
          ))
        ) : !!preview ? (
          productList.map((pr, index) => {
            if (index >= previewItems) {
              return null;
            }
            return getItem({ index });
          })
        ) : (
          <VirtualizedList
            items={productList}
            itemSize={productCardHeight}
            getItem={getItem}
            isItemLoaded={isItemLoaded}
            hasNextPage={
              !preview && selectedTagID !== null ? hasNextPage : false
            }
            loadMoreItems={loadMoreItems}
          />
        )}
      </Box>
      {!!preview &&
        !loadingProducts &&
        !loadingFilteredList &&
        remainingItems > 0 && (
          <Box px={2.5} mb={2.5}>
            <PrimaryButton
              variant="transparent"
              onClick={() =>
                saveInitialData(true, selectedTagID, filteredProducts)
              }
              label={`${t("view")}${` ${remainingItems} `}${t("more")}`}
            />
          </Box>
        )}
      <Box px={2.5} mb={1}>
        <Typography fontSize={14}>* {t("reviews_from_viator")}</Typography>
      </Box>
    </div>
  );
};

export default ProductList;
