import React, { useContext } from 'react';
import { loadData } from 'api';
import { Navigate } from 'react-router-dom';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import BPImage from 'assets/BoardingPassImage.webp';
import WarningIcon from 'assets/WarningIcon.jsx';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'App';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#000',
  },
  bgImage: {
    background: props => `linear-gradient(180deg, rgba(0,0,0,0) 20.14%, rgba(0,0,0,0.513912) 58.4%, #000000 96.67%), url(${props.bgImage}) no-repeat center center / cover`,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '61vh',
  },
  main: {
    display: 'flex',
    zIndex: 1,
    paddingBottom: '15vh',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 0,
  },
  input: {
    '& input': {
      color: '#FFF',
      marginBottom: 4,
      borderRadius: 999,
      padding: '12px 20px',
      border: '1.5px solid #FFF',
    },
    '&.-error input': { border: '1.5px solid #d32f2f' },
    '& fieldset': { border: 'none !important' },
  },
  btn: {
    color: '#000 !important',
    textTransform: 'none !important',
    backgroundColor: '#FFF !important',
    borderRadius: '999px !important',
    padding: '8px 30px !important',
  },
}))



export default function EnterCode({ setAuth }) {
  const classes = useStyles({ bgImage: BPImage })
  const { t } = useTranslation()
  const [code, setCode] = React.useState('')
  const { setKc } = useContext(AppContext)
  const [error, setError] = React.useState(false)
  const [redirect, setRedirect] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)


  const handleError = () => {
    setIsLoading(prev => false)
    setError(prev => true)
  }

  const loadKeycard = () => {
    setIsLoading(prev => true)
    loadData(code).then(r => {
      if (!r.data) {
        handleError()
        return
      }
      setKc(r.data)
      setAuth(code)
      setRedirect(prev => r.data.view)
    }).catch(e => {
      console.log("ERROR GETTING KEYCARD", e)
      handleError()
    })
  }

  const handleCodeChange = (e) => {
    const val = e.target.value
    setCode(prev => val)
    if (!!error) { setError(prev => false) }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      loadKeycard()
      e.preventDefault()
    }
  }


  if (!!redirect) { return <Navigate to={`/?auth=${code}`} replace /> }
  return <div className={classes.root}>
    <div className={classes.bgImage} />
    <main className={classes.main}>
      <Typography variant='h4' color='#FFF' textAlign='center' fontWeight={700}>
        {t("enter_bp_code")}
      </Typography>
      <div style={{ height: 10 }} />
      <Typography color='#FFF' textAlign='center'>
        {t("enter_code_instruction")}.
      </Typography>
      <div style={{ height: 30 }} />
      <TextField
        id={'code-input'}
        focused
        fullWidth
        value={code}
        className={`${classes.input} ${!!error ? '-error' : ''}`}
        helperText={!!error && <><WarningIcon style={{ marginBottom: -12 }} />{t("code_not_valid")}</>}
        error={!!error}
        onChange={handleCodeChange}
        onKeyDown={handleKeyPress}
      />
      <div style={{ height: 30 }} />
      <Button
        size='large'
        disabled={isLoading}
        variant='contained'
        className={classes.btn}
        onClick={loadKeycard}
      >{isLoading
        ? <CircularProgress color='inherit' size={26} />
        : t("submit")
        }</Button>
    </main>
  </div>
}