import React, { useMemo } from "react";
import { alpha, Button, darken, lighten, useTheme } from "@mui/material";
import LoadingInputLabel from "../input/LoadingInputLabel";

const PrimaryButton = ({
  label,
  variant = "main", // main | contrast | transparent
  disabled,
  loading,
  onClick,
  customColors = { bg: "#000000", color: "#FFFFFF" },
  ...otherProps
}) => {
  const theme = useTheme();
  const colorProps = useMemo(() => {
    switch (variant) {
      case "contrast":
        return {
          root: { color: "primary.main", backgroundColor: "primary.contrast" },
          disabled: {
            color: theme.palette.primary.mainLight + "!important",
            backgroundColor: theme.palette.primary.contrast + "!important",
          },
          hover: {
            backgroundColor: darken(theme.palette.primary.contrast, 0.1),
          },
        };
      case "transparent":
        return {
          root: {
            color: "primary.main",
            backgroundColor: "primary.contrastLight",
          },
          disabled: {
            color: theme.palette.primary.main + "!important",
            backgroundColor: theme.palette.primary.contrastLight + "!important",
          },
          hover: {
            backgroundColor: darken(theme.palette.primary.contrastLight, 0.1),
          },
        };
      case "custom":
        return {
          root: {
            color: customColors.color,
            backgroundColor: customColors.bg,
          },
          disabled: {
            color: customColors.color + "!important",
            backgroundColor: alpha(customColors.bg, 0.5) + "!important",
          },
          hover: {
            backgroundColor: lighten(customColors.bg, 0.2),
          },
        };

      default:
        return {
          root: { color: "primary.contrast", backgroundColor: "primary.main" },
          disabled: {
            color: theme.palette.primary.contrastLight + "!important",
            backgroundColor: theme.palette.primary.main + "!important",
          },
          hover: {
            backgroundColor: darken(theme.palette.primary.main, 0.1),
          },
        };
    }
  }, [variant, theme]);

  return (
    <Button
      disableRipple
      disabled={disabled}
      onClick={onClick}
      fullWidth
      size="large"
      variant="contained"
      sx={{
        fontFamily: '"Montserrat", serif',
        borderRadius: 30,
        fontWeight: 500,
        fontSize: 16,
        flex: 1,
        transition: "0.15s",
        boxShadow: "none",
        color: colorProps.root.color,
        backgroundColor: colorProps.root.backgroundColor,
        lineHeight: "18px",
        padding: theme.spacing(1.5, 2.5),
        ":disabled": {
          color: colorProps.disabled.color,
          backgroundColor: colorProps.disabled.backgroundColor,
          opacity: 0.5,
        },
        ":hover": { backgroundColor: colorProps.hover.backgroundColor },
      }}
      {...otherProps}
    >
      {loading ? (
        <LoadingInputLabel transparent={variant === "transparent"} />
      ) : (
        label
      )}
    </Button>
  );
};

export default PrimaryButton;
