import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { isIOS, isMacOs } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AppContext, allowedViewsToShowInfo } from "App";
import { loadData, loadProductData } from "api";
import { getAppleWallet } from "helpers";
import i18next from "i18next";
import getTheme from "theme";
// UI
import { makeStyles } from "@mui/styles";
import { Box, ButtonBase, IconButton, Badge, Modal } from "@mui/material";
import { AppleWalletIcon } from "assets/Icons.jsx";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
// Custom
import LoadingScreen from "components/Loading";
import SectionHeaderCard from "components/Cards/SectionHeaderCard";
import BrandingFooter from "components/BrandingFooter";
import LocalServicesGrid from "components/Grids/LocalServicesGrid";
import ProductList from "ui/component/product/list/ProductList";
import Upsells from "ui/component/upsell/Upsells";
import BasePage from "ui/base/panel/BasePage";
import OrdersList from "ui/component/product/list/OrdersList";
const invalidOrderStatus = ["unpaid", "refunded", "cancelled", "failed"];

const defaultBGImg =
  "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+Local+Resturant.webp";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    width: "-webkit-fill-available",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "auto",
  },
  content: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    maxWidth: 800,
  },
}));

const UpgradesPage = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();
  const {
    kc,
    auth,
    view,
    setKc,
    setView,
    setTheme,
    loadingKeycard,
    loadingProducts,
    setLoadingKeycard,
    setLoadingProducts,
  } = useContext(AppContext);
  const { listing_id } = useParams();
  const upsellsRef = useRef(null);
  const productListRef = useRef(null);
  const localServicesRef = useRef(null);
  const [fullListOpen, setFullListOpen] = useState(false);
  const [productsListData, setProductsListData] = useState({
    initialTag: null,
    initialProducts: [],
  });
  const isListingBP = !location.search;
  const components = kc?.components ?? {};
  const bookingId = kc?.booking_id;
  const hasUpsells =
    !!components.upsells?.length ||
    !!components.purchased_upsells?.length ||
    !!components.viator?.products?.length;
  const purchasedUpsells = useMemo(() => {
    return (components?.purchased_upsells ?? []).filter(
      (p_u) => !!p_u.purchased || !invalidOrderStatus.includes(p_u.bill.status)
    );
  }, [kc]);

  function redirectToHome() {
    window.location.replace(
      location.pathname.replace("/upgrades", isListingBP ? "/" : "") +
        location.search
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    let timer = null;
    timer = setTimeout(() => {
      if (location.state?.section === "upsells" && !!upsellsRef.current) {
        upsellsRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (
        location.state?.section === "local_services" &&
        !!localServicesRef.current
      ) {
        localServicesRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (
        location.state?.section === "products" &&
        !!productListRef.current
      ) {
        productListRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    });

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!!location?.state?.closeProduct) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const goToPurchases = () => {
    let path = !!listing_id
      ? `/${listing_id}/purchases`
      : `/purchases/?auth=${auth}`;
    navigate(path);
  };

  useEffect(() => {
    if (!kc) {
      setLoadingKeycard((prev) => true);
      const keycardPromise = loadData(auth, listing_id, null);
      const productPromise = loadProductData(auth, listing_id, null, true);

      keycardPromise
        .then((r) => {
          setKc(r.data);
          const dataComponents = r.data.components;
          const guest_language = dataComponents?.guest?.language;
          if (!!guest_language) {
            i18next.changeLanguage(guest_language);
          }
          let metadata = dataComponents.metadata;
          document.body.style.background = metadata.color2;
          document
            .querySelector('meta[name="theme-color"]')
            .setAttribute("content", metadata.color2);
          window.title = `Boarding Pass - ${dataComponents.metadata.name}`;
          setTheme(getTheme(metadata));
          setView(r.data.view);
          setLoadingKeycard((prev) => false);
          if (!allowedViewsToShowInfo.includes(r.data.view)) {
            redirectToHome();
          }
        })
        .catch((e) => {
          console.log("ERROR GETTING KEYCARD", e);
          setView("error");
          setLoadingKeycard((prev) => false);
        });

      setLoadingProducts(true);
      productPromise
        .then(async (r) => {
          const keycardData = await keycardPromise;
          const updated_components = {
            ...(keycardData.data.components || {}),
            ...r.data,
          };
          setKc({ ...keycardData.data, components: updated_components });
        })
        .catch((e) => {
          console.log("ERROR GETTING PRODUCT DATA", e);
          setView("error");
          setLoadingKeycard((prev) => false);
        })
        .finally(() => setLoadingProducts(false));
    } else if (!hasUpsells) {
      redirectToHome();
    }
  }, []);

  const upcomingUpgrades = !!purchasedUpsells.length && (
    <Box mx={2.5} mt={5}>
      <OrdersList purchases={purchasedUpsells} loading={loadingProducts} />
    </Box>
  );

  if (loadingKeycard) {
    return <LoadingScreen />;
  }
  return (
    <div className={classes.container}>
      <SectionHeaderCard
        title={t("upgrades")}
        img={
          loadingProducts
            ? null
            : components.upsells?.[0]?.header_image ?? defaultBGImg
        }
        actions={
          !!components?.purchased_upsells?.length && (
            <IconButton
              style={{ marginRight: 20, padding: 0 }}
              onClick={goToPurchases}
            >
              <Badge
                badgeContent={components?.purchased_upsells?.length}
                color="primary"
              >
                <ShoppingBasketIcon sx={{ fontSize: 45 }} />
              </Badge>
            </IconButton>
          )
        }
      />
      <div className={classes.content}>
        {upcomingUpgrades}
        {/* Upsells */}
        <Box mt={5}>
          <Upsells upsellsRef={upsellsRef} />
        </Box>
        {/* Viator */}
        {fullListOpen ? (
          <Modal open>
            <div>
              <BasePage>
                <Box maxWidth={800} width={"100%"} height={"100%"}>
                  <ProductList
                    disableGutters
                    initialData={productsListData}
                    saveInitialData={(openFullList, tag, products) => {
                      setProductsListData({
                        initialTag: tag,
                        initialProducts: products,
                      });
                      setFullListOpen(openFullList);
                    }}
                  />
                </Box>
              </BasePage>
            </div>
          </Modal>
        ) : (
          (loadingProducts || !!components?.viator) && (
            <Box mt={!loadingProducts && !components.upsells?.length ? -5 : 0}>
              <ProductList
                preview
                sectionRef={productListRef}
                initialData={productsListData}
                saveInitialData={(openFullList, tag, products) => {
                  setProductsListData({
                    initialTag: tag,
                    initialProducts: products,
                  });
                  setFullListOpen(openFullList);
                }}
              />
            </Box>
          )
        )}
        {/* Local Services */}
        <Box ref={localServicesRef}>
          <LocalServicesGrid />
        </Box>
      </div>
      {/* Branding footer */}
      <Box mt={2.5} display="flex" flexDirection="column" alignItems="center">
        <BrandingFooter
          metadata={components?.metadata}
          extras={
            view !== "revoked" && (isMacOs || isIOS) ? (
              <ButtonBase
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() =>
                  getAppleWallet(auth, bookingId, setLoadingKeycard)
                }
              >
                <AppleWalletIcon />
              </ButtonBase>
            ) : null
          }
        />
      </Box>
    </div>
  );
};

export default UpgradesPage;
