import React from "react";
import styled from "@emotion/styled";
import { InputLabel } from "@mui/material";

const CustomizedInputLabel = styled(InputLabel)(({ theme, styleProps }) => ({
  minHeight: 23,
  position: "relative",
  color: styleProps?.color ?? theme.palette.primary.main,
  backgroundColor:
    styleProps?.backgroundColor ?? theme.palette.primary.contrast,
  transform: "none",
  fontSize: 16,
  "&.Mui-focused": {
    color: styleProps?.color ?? theme.palette.primary.main,
    backgroundColor:
      styleProps?.backgroundColor ?? theme.palette.primary.contrast,
  },
}));

const BaseInputLabel = ({ id, styleProps, children, ...otherProps }) => {
  return (
    <CustomizedInputLabel
      shrink
      id={id}
      styleProps={styleProps}
      {...otherProps}
    >
      {children}
    </CustomizedInputLabel>
  );
};

export default BaseInputLabel;
