import React, { useMemo, useState, useCallback, useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getCurrency } from "helpers";
import { isBefore, isValid } from "date-fns";
import { useTranslation } from "react-i18next";
// UI
import { GlobalStyles, useTheme, InputAdornment } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import styled from "@emotion/styled";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import BaseInput from "./BaseInput";

const minDate = new Date("1/1/1910");
const today = new Date().setHours(0, 0, 0, 0);

const CustomDatePicker = ({ field, index, handleChangeValue }) => {
  const isAvailabilityField = field.field === "TRAVEL_DATE";
  const theme = useTheme();
  const { t } = useTranslation();
  const didChange = useRef(false);
  const fieldError = useRef(false);
  const [selectedDate, setSelectedDate] = useState(
    field.value ? new Date(field.value) : null
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  let dateLabel = t(field.name || "date");

  const priceData = useMemo(() => {
    if (!field.field_options) {
      return null;
    }
    const newPriceData = {};
    field.field_options.forEach((opt) => {
      newPriceData[opt.field] = getCurrency({
        price: opt.field_label,
        currency: opt.field_sub_label,
        hideSuffix: true,
        showPrefix: true,
      });
    });
    return newPriceData;
  }, [field]);

  const isDateDisabled = useCallback(
    (date) =>
      isAvailabilityField &&
      (isBefore(date, today) || !priceData?.[date.toISOString().split("T")[0]]),
    [isAvailabilityField, today, priceData]
  );

  const getDateFormatted = (date) =>
    date ? (isValid(date) ? date.toISOString().split("T")[0] : date) : null;

  const handleDateChange = (date) => {
    if (!didChange.current) {
      didChange.current = true;
    }
    setSelectedDate(date);
    handleChangeValue(
      index,
      getDateFormatted(date),
      isValid(date) ? false : fieldError.current !== false || !isValid(date)
    );
  };

  const getPriceForDate = useCallback(
    (date) => priceData?.[date?.toISOString().split("T")[0]] || "",
    [priceData]
  );

  const StyledPickersDay = useMemo(
    () =>
      styled(PickersDay)(({ theme, day }) => {
        const price = getPriceForDate(day);
        return {
          position: "relative",
          width: 55,
          height: 42,
          fontWeight: 800,
          paddingTop: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: isAvailabilityField ? "flex-start" : "center",
          color: theme.palette.getContrastText(theme.palette.primary.main),
          "&:hover": { backgroundColor: theme.palette.primary.contrast },
          "&:not(.Mui-disabled)::after": {
            content: price ? `"${price}"` : '""',
            position: "absolute",
            bottom: 4,
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: 9,
            fontWeight: 700,
            color: theme.palette.primary.contrast,
            "&:hover": {
              color: theme.palette.getContrastText(theme.palette.primary.main),
            },
          },
          "&:not(.Mui-disabled):hover::after": {
            color: theme.palette.getContrastText(
              theme.palette.primary.contrast
            ),
          },
          "&.MuiPickersDay-today": {
            border: `1px solid ${theme.palette.primary.contrast}`,
            color: theme.palette.primary.contrast,
            fontWeight: 800,
            backgroundColor: theme.palette.primary.main,
            "&::after": {
              color: theme.palette.primary.contrast + "!important",
            },
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.contrast + "!important",
            color: theme.palette.primary.main + "!important",
            fontWeight: 800,
            "&::after": { color: theme.palette.primary.main + "!important" },
          },
          "&.Mui-disabled": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            fontWeight: 600,
            opacity: 0.5,
          },
        };
      }),
    [getPriceForDate]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <GlobalStyles
        styles={{
          ".MuiCalendarPicker-viewTransitionContainer span.MuiTypography-root.MuiTypography-caption":
            {
              color: theme.palette.getContrastText(theme.palette.primary.main),
              fontWeight: 600,
              width: 55,
              opacity: 0.8,
            },
          ".MuiPickersArrowSwitcher-spacer": { width: "0px !important" },
          ".MuiPickersArrowSwitcher-root > button": {
            position: "absolute",
            top: -2,
          },
          ".MuiPickersArrowSwitcher-root > button:nth-of-type(1)": { left: 6 },
          ".MuiPickersArrowSwitcher-root > button:nth-of-type(2)": { right: 6 },
          ".MuiCalendarOrClockPicker-root": {
            flexDirection: "column-reverse !important",
          },
          ".MuiCalendarPicker-root button.MuiIconButton-sizeSmall": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            fontWeight: 800,
          },
          ".MuiPickersCalendarHeader-label": {
            fontSize: "18px",
            fontWeight: 700,
          },
          ".MuiCalendarPicker-root div.PrivatePickersFadeTransitionGroup-root":
            {
              color: theme.palette.getContrastText(theme.palette.primary.main),
              fontWeight: 800,
            },
          ".MuiPickersCalendarHeader-root": {
            position: "relative",
            padding: "0px !important",
            marginBottom: theme.spacing(2.5) + "!important",
          },
          ".MuiPickersCalendarHeader-labelContainer": {
            color:
              theme.palette.getContrastText(theme.palette.primary.main) +
              "!important",
            marginLeft: "auto",
          },
          ".MuiPickersCalendarHeader-labelContainer > button": {
            display: "none",
          },
          ".PrivatePickersYear-root": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            fontWeight: 800,
          },
          ".MuiCalendarOrClockPicker-root > div, .MuiCalendarPicker-root": {
            maxHeight: "500px !important",
          },
        }}
      />
      <DesktopDatePicker
        label={dateLabel}
        value={selectedDate}
        minDate={minDate}
        open={dialogOpen}
        shouldDisableDate={isDateDisabled}
        onChange={handleDateChange}
        onClose={() => setDialogOpen(false)}
        onOpen={() => setDialogOpen(true)}
        onAccept={(value) => {
          fieldError.current = false;
        }}
        onError={(reason, value) => {
          fieldError.current = isValid(value) ? false : reason;
        }}
        PaperProps={{
          sx: {
            backgroundColor: "primary.main",
            paddingX: 1.5,
            borderRadius: "20px",
          },
        }}
        PopperProps={{
          modifiers: [
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "viewport",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
              },
            },
          ],
        }}
        renderDay={(day, selectedDays, pickersDayProps) => (
          <StyledPickersDay {...pickersDayProps} day={day} />
        )}
        renderInput={(params) => {
          const inputId = `field-${field.field}`;
          params.inputProps = {
            ...params.inputProps,
            placeholder: `Select ${params.label}`,
          };
          return (
            <BaseInput
              {...params}
              id={inputId}
              fullWidth
              label={params.label}
              required={field.required}
              error={field.error}
              onClick={() => setDialogOpen(true)}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{ position: "absolute", right: 12 }}
                >
                  <CalendarIcon sx={{ color: "primary.main" }} />
                </InputAdornment>
              }
              customErrorLabel={
                fieldError.current === null ||
                (!isValid(selectedDate) && !didChange.current)
                  ? t("please_select")
                  : t("invalid_date")
              }
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
