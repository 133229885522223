import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { makeStyles } from "@mui/styles";
import { IconButton, SvgIcon } from "@mui/material";
import { ReactComponent as LeftArrowIcon } from "assets/icons/chevron-left.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/chevron-right.svg";
import "react-horizontal-scrolling-menu/dist/styles.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    position: "absolute !important",
    top: "50%",
    zIndex: 2,
    backgroundColor: `${theme.palette.primary.main + "a6"} !important`,
    transform: "translate(0,-50%)",
    "&.left": { left: "5px !important" },
    "&.right": { right: "5px !important" },
    "&.disabled": { visibility: "hidden !important" },
  },
  arrowIcon: { color: theme.palette.primary.contrast },
}));

function Arrow({ left = false, disabled, onClick }) {
  const classes = useStyles();

  if (isMobile) {
    return null;
  }
  return (
    <IconButton
      disabled={disabled}
      className={clsx(classes.iconBtn, {
        left: left,
        right: !left,
        disabled: disabled,
      })}
      onClick={onClick}
    >
      <SvgIcon
        viewBox="0 0 24 24"
        className={classes.arrowIcon}
        component={!!left ? LeftArrowIcon : RightArrowIcon}
      />
    </IconButton>
  );
}

const LeftArrow = () => {
  const visibility = useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);
  return (
    <Arrow
      left
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    />
  );
};

const RightArrow = () => {
  const visibility = useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);
  return (
    <Arrow
      disabled={isLastItemVisible}
      onClick={() => visibility.scrollNext()}
    />
  );
};

const Carousel = ({ children, menuRef, wrapperClass }) => {
  return (
    <ScrollMenu
      apiRef={menuRef}
      LeftArrow={isMobile ? undefined : LeftArrow}
      RightArrow={isMobile ? undefined : RightArrow}
      wrapperClassName={wrapperClass}
    >
      {children}
    </ScrollMenu>
  );
};

export default Carousel;
