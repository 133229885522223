import React from "react";
import { alpha, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tag: {
    borderRadius: 360,
    padding: theme.spacing(1.5, 2.5),
    backgroundColor: alpha(
      theme.palette.getContrastText(theme.palette.primary.contrast),
      0.08
    ),
    color: theme.palette.primary.main,
    width: "max-content",
    marginRight: theme.spacing(1.5),
    lineHeight: "20px",
    fontSize: 14,
    fontWeight: 500,
    cursor: "pointer",
    WebkitTapHighlightColor: "transparent",
    "&.-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrast,
    },
  },
}));

const ButtonTag = ({ text, selected, onClick, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      className={[classes.tag, selected ? "-selected" : ""].join(" ")}
      onClick={onClick}
      {...props}
    >
      {text}
    </Box>
  );
};

export default ButtonTag;
