import React from "react";
import { Stack, Typography } from "@mui/material";
import ProductCardImage from "ui/base/img/ProductCardImage";
import styled from "@emotion/styled";
import PriceTag from "ui/base/tag/PriceTag";

const Card = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastLight,
  borderRadius: "10px",
  padding: theme.spacing(1.5),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: "28px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  wordBreak: "break-word",
  width: "100%",
  lineClamp: 2,
  WebkitLineClamp: 2,
}));

const PurchasedProductCard = ({
  title = "",
  image,
  price = "",
  status = "",
  onClick,
}) => {
  return (
    <Card onClick={onClick} flexDirection={"row"} alignItems={"center"} gap={1}>
      {!!image && (
        <ProductCardImage src={image} alt={title} />
      )}
      <Stack flex={1} flexDirection={"column"}>
        <Title fontSize={16} title={title}>
          {title}
        </Title>
        <Typography fontSize={14}>{status}</Typography>
      </Stack>
      {!!price && <PriceTag price={price} variant="contrast" />}
    </Card>
  );
};

export default PurchasedProductCard;
