import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { getCurrency, getStatusLabel, getTravelers } from "helpers";
import { format } from "date-fns";
// UI & Components
import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";
import { Collapse, Paper, Stack, SvgIcon, Typography } from "@mui/material";
import styled from "@emotion/styled";
import InfoCard from "ui/base/card/InfoCard";
import RowLabel from "ui/base/label/RowLabel";
import CustomRow from "ui/base/grid/CustomRow";
import LinkButton from "ui/base/button/LinkButton";
import PrimaryButton from "ui/base/button/PrimaryButton";
import OutlinedButton from "ui/base/button/OutlinedButton";
import ProductCardImage from "ui/base/img/ProductCardImage";

const Line = styled.div(({ theme }) => ({
  width: "100%",
  opacity: 0.5,
  borderBottom: `1px dashed ${theme.palette.primary.main}`,
}));

const OrderDetailsCard = ({
  bill,
  language,
  headerImage,
  orderRef,
  expanded,
  onExpand,
  onContact = () => {},
}) => {
  const { t } = useTranslation();
  const {
    title,
    session_id,
    status,
    total,
    currency,
    voucher,
    custom,
    product_type,
    cancellation_policy,
  } = bill;
  const image = headerImage ?? custom?.header_image?.value;
  const sentTo = custom?.comm_email?.value;
  const date = custom?.travel_date?.value;
  const firstName = custom?.booker_first_name?.value;
  const lastName = custom?.booker_last_name?.value;
  const phone = custom?.comm_phone?.value;
  const specialRequirements = custom?.special_requirements?.value;
  const cancellationPolicy =
    status === "requires_action"
      ? t("requires_action_cancellation")
      : cancellation_policy?.description?.find((d) => d.language === language)
          ?.value;
  const travelers = getTravelers(custom?.travelers?.value ?? [], t);
  const isViator = product_type === "viator";

  const getRow = (label, value, valueProps) => {
    if (!value) {
      return null;
    }
    return (
      <CustomRow gap={2}>
        <RowLabel>{label}</RowLabel>
        <Typography
          align="right"
          sx={{ overflowWrap: "anywhere" }}
          {...valueProps}
        >
          {value}
        </Typography>
      </CustomRow>
    );
  };

  return (
    <Stack
      bgcolor={"primary.contrastLight"}
      borderRadius={"10px"}
      p={2.5}
      ref={orderRef}
      spacing={1.5}
    >
      <CustomRow spacing={1}>
        <Typography
          fontSize={isMobile ? 20 : 22}
          lineHeight={"30px"}
          sx={{ flex: 1 }}
        >
          {title}
        </Typography>
        {!!image && <ProductCardImage src={image} alt={title} />}
      </CustomRow>
      {!!voucher && (
        <CustomRow>
          <PrimaryButton
            label={t("see_voucher")}
            component="a"
            target="_blank"
            href={voucher.url}
            rel="noopener noreferrer"
            endIcon={
              <SvgIcon
                component={ExternalLinkIcon}
                viewBox="0 0 17 16"
                sx={{ fontSize: "18px !important" }}
              />
            }
          />
        </CustomRow>
      )}
      {getRow(t("status"), getStatusLabel(status, t), {
        textTransform: "uppercase",
      })}
      {getRow(
        t("total_price"),
        getCurrency({
          price: total ?? 0,
          currency: currency,
        })
      )}
      {getRow(t("booking_ref"), session_id)}
      {(!!sentTo || !!date) && <Line />}
      {!!sentTo && getRow(t("sent_to"), sentTo)}
      {!!date && getRow(t("date"), format(new Date(date), "E, PP"))}
      <Collapse in={expanded} timeout={200}>
        <Paper elevation={0} sx={{ backgroundColor: "primary.contrastLight" }}>
          <Stack gap={1.5}>
            {!!travelers && getRow(t("travelers"), travelers)}
            {getRow(t("first_name"), firstName)}
            {getRow(t("last_name"), lastName)}
            {getRow(t("phone_number"), phone)}
            {!!specialRequirements &&
              getRow(t("special_requirements"), specialRequirements)}
          </Stack>
        </Paper>
      </Collapse>
      {isViator && (
        <LinkButton
          label={expanded ? t("see_less") : t("see_more")}
          onClick={onExpand}
          sx={{ mt: expanded ? 1.5 : 0 + "!important" }}
        />
      )}
      {(isViator || !!cancellationPolicy) && <Line />}
      <Stack flexDirection={"column"} gap={1.5}>
        {!!cancellationPolicy && <InfoCard text={cancellationPolicy} />}
        {isViator && (
          <>
            <Typography>{t("issues_with_upgrade")}</Typography>
            <OutlinedButton
              fullWidth
              onClick={onContact}
              label={t("contact")}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default OrderDetailsCard;
