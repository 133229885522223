import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import loadPayment from "payment";
import BaseSelector from "ui/base/input/BaseSelector";
import { countries } from "configuration/constants";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import BaseInput from "ui/base/input/BaseInput";

const countryOptions = Object.entries(countries).map((c) => ({
  value: c[0],
  label: JSON.parse(`"${c[1]}"`),
}));

const styleProps = {
  color: "#000000",
  borderColor: "#B0B2B5",
  backgroundColor: "#FFFFFF",
  focusBorderColor: "#000000",
  borderRadius: 4,
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: theme.spacing(1.5),
    minHeight: 395,
    "& iframe": { border: "none" },
  },
}));

const ViatorCheckoutForm = ({
  name,
  setPayment,
  setValidData,
  paymentSessionToken,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [validAddress, setValidAddress] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);

  useEffect(() => {
    if (!country || !zipCode?.trim()) {
      setValidAddress(false);
    } else {
      setValidAddress(true);
    }
  }, [country, zipCode]);

  useEffect(() => {
    setValidData(validAddress && validForm);
  }, [validForm, validAddress, setValidData]);

  const updateFunction = (e) => {
    if (e.hasOwnProperty("formValid")) {
      setValidForm(e.formValid);
    }
  };

  useEffect(() => {
    const renderOptions = {
      cardElementContainer: "card-frame-holder-module",
      cardholderName: name,
      onFormUpdate: updateFunction,
      styling: {
        colorTheme: "LIGHT",
        variables: {
          colorBackground: "#FFFFFF",
          colorPrimaryText: "#000000",
          fontSize: "1rem",
        },
      },
    };

    // This is the entry point to the module which will load the library code dynamically
    // The return is a promise that will resolve to the Payment object.
    const paymentPromise = loadPayment(paymentSessionToken);

    // When the promise resolves we can render the form:
    paymentPromise.then((newPayment) => {
      // Store a reference to payment to call in the submit function:
      setPayment(newPayment);

      // Render the form:
      newPayment.renderCard(renderOptions);
      setShowAddressFields(true);
    });
  }, [paymentSessionToken]);

  return (
    <div className={classes.container}>
      <div id="card-frame-holder-module" className="masked"></div>
      {showAddressFields && (
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <BaseSelector
            fullWidth
            label={t("country")}
            id={"country-select"}
            value={country}
            defaultOption={t("select_country")}
            onChange={(e) => setCountry(e.target.value)}
            options={countryOptions}
            styleProps={styleProps}
          />
          <BaseInput
            fullWidth
            id={"zip-code"}
            value={zipCode}
            label={t("zip_code")}
            placeholder=""
            styleProps={styleProps}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </Stack>
      )}
    </div>
  );
};

export default ViatorCheckoutForm;
