import React from "react";
import styled from "@emotion/styled";
import { alpha, Typography } from "@mui/material";

const Label = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": { color: alpha(theme.palette.primary.main, 0.75) },
}));

export default function RowLabel({ ...props }) {
  return <Label {...props} />;
}
