import React from "react";
import {
  alpha,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import HTMLContent from "components/HTMLContent";
import { useTranslation } from "react-i18next";
import BaseInputLabel from "ui/base/input/InputLabel";
import WarningTag from "ui/base/tag/WarningTag";

const CustomListItem = styled(ListItemButton)(({ theme }) => ({
  border: `1.5px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: "10px",
  padding: 0,
  "&:hover": { backgroundColor: alpha(theme.palette.primary.main, 0.1) },
}));

const ItemContent = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
  gap: theme.spacing(1.5),
  alignItems: "flex-start",
}));

export default function ProductSkuSelector({
  id,
  value = "",
  error,
  disabled,
  customErrorLabel,
  onChange,
  options = [],
}) {
  const { t } = useTranslation();
  const errorLabel = customErrorLabel ?? t("please_select");

  return (
    <div>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={1}
        rowGap={0.5}
        columnGap={2}
        sx={{ flexFlow: "wrap" }}
      >
        <BaseInputLabel id={id} flex={1} sx={{ minWidth: "fit-content" }}>
          {`${
            options.length > 1
              ? t("product_options")
              : t("product_options_singular")
          } *`}
        </BaseInputLabel>
        {!!error && <WarningTag label={errorLabel} />}
      </Stack>
      <Stack flexDirection={"column"} gap={1.5} id={id}>
        {options.map((opt) => {
          const isSelected = value === opt.value;
          return (
            <CustomListItem
              key={opt.value}
              disabled={opt.disabled}
              disableRipple
            >
              <ItemContent
                role={undefined}
                disableRipple
                disabled={disabled}
                onClick={() => onChange(opt.value)}
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Radio
                    checked={isSelected}
                    value={opt.value}
                    sx={{ color: "primary.main", padding: 0 }}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography>{opt.label}</Typography>}
                  sx={{ margin: 0 }}
                  secondary={
                    isSelected ? (
                      <HTMLContent
                        v={opt.description}
                        styleOptions={{
                          fontSize: 13,
                          fontWeight: 500,
                          lineHeight: "20px",
                          opacity: 0.8,
                        }}
                      />
                    ) : undefined
                  }
                />
              </ItemContent>
            </CustomListItem>
          );
        })}
      </Stack>
    </div>
  );
}
