import React from "react";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import CustomRow from "ui/base/grid/CustomRow";
import RowLabel from "ui/base/label/RowLabel";

const ProductContactDetailsDialog = ({ bookingRef, setOpen }) => {
  const { t } = useTranslation();

  const getCancellationContent = () => {
    return (
      <Stack gap={2}>
        <CustomRow gap={1} width={"100%"} alignItems="flex-end">
          <RowLabel align="left" flex={1}>
            {t("booking_ref")}
          </RowLabel>
          <Typography align="right" sx={{ overflowWrap: "anywhere" }}>
            {bookingRef}
          </Typography>
        </CustomRow>
        <Typography textAlign={"justify"}>{t("contact_modal_body")}</Typography>
        <CustomRow gap={1} width={"100%"} alignItems="flex-end">
          <RowLabel align="left" flex={1}>
            {t("email")}
          </RowLabel>
          <Typography
            align="right"
            component="a"
            href="mailto:travelagents@viator.com"
            sx={{
              overflowWrap: "anywhere",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {"travelagents@viator.com"}
          </Typography>
        </CustomRow>
        <CustomRow gap={1} width={"100%"} alignItems="flex-end">
          <RowLabel align="left" flex={1}>
            {t("phone_toll_fee")}
          </RowLabel>
          <Typography
            component="a"
            href="tel:+18666485873"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {"+1-866-648-5873"}
          </Typography>
        </CustomRow>
        <CustomRow gap={1} width={"100%"} alignItems="flex-end">
          <RowLabel align="left" flex={1}>
            {t("phone_international")}
          </RowLabel>
          <Typography
            component="a"
            href="tel:+17026485873"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {"+1 (702) 648-5873"}
          </Typography>
        </CustomRow>
      </Stack>
    );
  };

  return (
    <>
      <Modal
        open={!!bookingRef}
        setOpen={() => setOpen(null)}
        text={getCancellationContent()}
        title={t("contact_modal_header")}
        buttons={[]}
      />
    </>
  );
};

export default ProductContactDetailsDialog;
